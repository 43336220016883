import React, { memo } from 'react'
import { Contact } from '../../../db/interfaces/Contact'
import { ConfirmButton } from '../../../components/ConfirmButton'

export interface ContactListProps {
  contacts: Contact[]
  onDelete: (id: string | number) => void | Promise<void>
  onEdit: (id: number) => void
}

export const ContactList = memo<ContactListProps>(function ContactList(props) {
  const { contacts, onDelete, onEdit } = props
  return (
    <div className="contacts-list list">
      {contacts.map((contact, i) => (
        <div key={`contact-${i}`} className="box">
          <div className="box-row box-row-header">
            <div className="category box-cell">{contact.name}</div>
            <div className="date box-cell">{contact.type}</div>
          </div>

          <div className="box-row">
            <div className="title box-cell"> Address: </div>
            <div className="value box-cell">{contact.address}</div>
          </div>

          <div className="box-row">
            <div className="title box-cell"> Telephone number: </div>
            <div className="value box-cell">{contact.phone}</div>
          </div>

          <div className="box-row">
            <div className="title box-cell"> Email address: </div>
            <div className="value box-cell">{contact.email}</div>
          </div>

          <div className="box-row">
            <div className="title box-cell"> Description: </div>
            <div className="value box-cell">{contact.description}</div>
          </div>

          <div className="box-row actions">
            <div className="box-cell">
              <button
                onClick={() => onEdit(contact.id)}
                className="btn flat btn-primary-2-inversed w-full action-edit"
              >
                Edit
              </button>
            </div>
            <div className="box-cell">
              <ConfirmButton
                onConfirm={() => onDelete(contact.id)}
                className="btn flat btn-primary-2-inversed w-full action-delete"
              >
                Delete
              </ConfirmButton>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
})

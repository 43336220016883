import React, { memo, useRef, useState } from 'react'
import { useHistory } from 'react-router'

import { PageHeader } from '../../components/app/PageHeader'
import { MedicalHistoryIcon } from '../../components/icons/MedicalHistoryIcon'
import { AccordionHeader } from '../../components/accordion/AccordionHeader'
import { ChevronCircleIcon } from '../../components/icons/ChevronCircleIcon'

import condAndTreatIconSrc from '../../assets/images/cond-and-treat-icon.svg'
import travelIconSrc from '../../assets/images/travel-icon.svg'
import wellnessIconSrc from '../../assets/images/wellness-icon.svg'

const goToConditionsExternal = () => {
  window.open('https://www.nhs.uk/conditions/')
}

const goToTravelExternal = () => {
  window.open('https://travelhealthpro.org.uk/countries')
}

const goToWelnessExternal = () => {
  window.open('https://wellbeing.medi-health.info/')
}

export const HealthInformationPage = memo(function HealthInformationPage() {
  const [searchText, setSearchText] = useState('')
  const submitSearch = () => {
    window.open(
      `https://medicalsolutions.medi-health.info/search/keyword?phrase=${searchText}&submit=Search`,
    )
  }
  return (
    <div className="page health-information-page pattern-bg">
      <PageHeader
        title="Health Information"
        icon={<MedicalHistoryIcon className="health-information-icon" />}
      />
      <div className="section-header">
        Access health information you can trust
      </div>
      <div className=" content paragraph">
        Over 1100 topics from a comprehensive medically validated UK database
        online
      </div>

      <div className="health-infromation-search-container flex-center">
        <div className="health-information-search-control">
          <input
            onChange={e => setSearchText(e.currentTarget.value)}
            value={searchText}
            className="search-information-input"
            placeholder="Search..."
            type="text"
          />
          <button
            disabled={!searchText}
            onClick={submitSearch}
            className="search-button"
          >
            <svg width="13" height="13" viewBox="0 0 13 13">
              <path
                fill="#fff"
                d="M9.83 7.393H.93C.418 7.393 0 6.99 0 6.503c0-.493.418-.896.931-.896h8.898L5.586 1.524a.87.87 0 0 1 0-1.266.967.967 0 0 1 1.315 0l5.83 5.616.033.032.013.013.02.026.02.026a.453.453 0 0 1 .068.104h.007l.014.032.013.026c.014.04.034.078.04.117l.007.02.02.071.007.084.007.052v.046l-.007.058-.006.078-.02.071-.007.02c-.007.039-.027.078-.041.117l-.013.026-.014.032h-.007c-.013.04-.04.065-.067.104l-.02.02-.02.032-.014.013-.034.039-5.829 5.609a.967.967 0 0 1-1.315 0 .87.87 0 0 1 0-1.266l4.243-4.083"
              />
            </svg>
          </button>
        </div>
      </div>

      <div className="section-header nhs-section-header">
        NHS Health Information
      </div>
      <AccordionHeader
        onClick={goToConditionsExternal}
        iconComponent={ChevronCircleIcon}
      >
        <img
          className="accordion-title-icon"
          src={condAndTreatIconSrc}
          alt="icon"
        />
        Conditions and Treatments
      </AccordionHeader>
      <AccordionHeader
        className="health-info-travel"
        onClick={goToTravelExternal}
        iconComponent={ChevronCircleIcon}
      >
        <img className="accordion-title-icon" src={travelIconSrc} alt="icon" />
        Travel
      </AccordionHeader>
      <AccordionHeader
        className="health-info-wellness"
        onClick={goToWelnessExternal}
        iconComponent={ChevronCircleIcon}
      >
        <img
          className="accordion-title-icon"
          src={wellnessIconSrc}
          alt="icon"
        />
        Wellness
      </AccordionHeader>
    </div>
  )
})

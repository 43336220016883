import React, { memo } from 'react'
import cx from 'clsx'

export interface TextInputProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  error?: string | null
  label: string
  inputRef?: any
}
export const TextInput = memo<TextInputProps>(function TextInput(props) {
  const { label, error, inputRef, ...inputProps } = props
  return (
    <div className="form-control">
      <label>{label}</label>
      <input
        ref={inputRef}
        {...inputProps}
        className={cx(
          'text-input',
          inputProps.className,
          error ? 'error' : null,
        )}
      />
      {error ? <div className="error-message">{error}</div> : null}
    </div>
  )
})

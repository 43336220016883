import React, { memo } from 'react'
import { useForm } from 'react-hook-form'

import { BloodPressure } from '../../../../db/interfaces/BloodPressure'
import { TextInput } from '../../../../components/form/TextInput'

export interface BloodPressureFormProps {
  onSubmit: (values?: any) => void
  onCancel: () => void
  bloodPressure?: BloodPressure
}

export const BloodPressureForm = memo<BloodPressureFormProps>(
  function BloodPressureForm(props) {
    const { onSubmit, onCancel, bloodPressure } = props

    const { register, handleSubmit } = useForm<BloodPressure>({
      defaultValues: bloodPressure,
    })

    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form">
          <TextInput inputRef={register} name="date" type="date" label="Date" />
          <TextInput inputRef={register} name="time" type="time" label="Time" />

          <TextInput
            inputRef={register}
            type="number"
            name="systolic"
            label="Systolic"
          />
          <TextInput
            inputRef={register}
            type="number"
            name="diastolic"
            label="Diastolic"
          />

          {bloodPressure ? (
            <input ref={register} name="id" type="hidden" />
          ) : null}

          <div className="button-container">
            <div className="form-control">
              <button type="submit" className="btn btn-primary-2 w-full">
                {bloodPressure ? 'Edit' : 'Add'}
              </button>
            </div>
            <div className="form-control">
              <button
                onClick={e => {
                  e.preventDefault()
                  onCancel()
                }}
                className="btn w-full"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </form>
    )
  },
)

import React, { memo } from 'react'
import { Link } from 'react-router-dom'

import { StethoscopeIcon } from '../../components/icons/StethoscopeIcon'
import { EConsultationIcon } from '../../components/icons/EConsultationIcon'
import { MessageIcon } from '../../components/icons/MessageIcon'
import { PhoneConsultationIcon } from '../../components/icons/PhoneConsultationIcon'
import { ScreenRoutes } from '../../types/ScreenRoutes'
import { PageHeader } from '../../components/app/PageHeader'
import { ClockIcon } from '../../components/icons/ClockIcon'

export const GpConsultationsPage = memo(function GpConsultationsPage() {
  return (
    <div className="page gp-consultations-page pattern-bg">
      <PageHeader
        title="GP Consultations"
        icon={<StethoscopeIcon className="stethoscope-icon" />}
      />

      <div className="content">
        <div className="section-header">
          Speak to a doctor at a time and place that suits you
        </div>
      </div>

      <div className="speak-to-doctor-section">
        <div className="speak-to-doctor-section-background" />
        <div className="button-tile-container">
          <div className="button-tile-col">
            <div className="button-tile button-tile-secondary">
              <div className="button-container">
                <Link className="button-tile-link" to={ScreenRoutes.E_CONSULT}>
                  <div className="button-container">
                    <div className="icon-container">
                      <EConsultationIcon className="button-icon" />
                    </div>
                    <div
                      style={{ display: 'flex', flex: 1, alignItems: 'center' }}
                    >
                      <div>
                        <button className="btn">eConsultations</button>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
            <div className="button-tile button-tile-secondary mt-1">
              <div className="button-container">
                <Link className="button-tile-link" to={ScreenRoutes.MESSAGE_DR}>
                  <div className="button-container">
                    <div className="icon-container">
                      <MessageIcon className="button-icon" />
                    </div>
                    <div
                      style={{ display: 'flex', flex: 1, alignItems: 'center' }}
                    >
                      <div>
                        <button className="btn">Message Dr.</button>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
          <div className="button-tile-col">
            <div className="button-tile button-tile-primary">
              <a href="tel:01423 798 198">
                <div className="button-container">
                  <div className="icon-container">
                    <PhoneConsultationIcon className="button-icon" />
                  </div>
                  <div
                    style={{ display: 'flex', flex: 1, alignItems: 'center' }}
                  >
                    <div>
                      <button className="btn">
                        Book a Phone Consultation 24/7
                      </button>
                    </div>
                  </div>
                </div>
              </a>

              <div className="button-container request-appointment-btn">
                <div className="btn-divider-text mt-1 mb-1">or</div>
                <Link to={ScreenRoutes.REQUEST_APPOINTMENT}>
                  <button className="btn">Request appointment online</button>
                </Link>
              </div>
            </div>

            <div className="button-tile button-tile-primary request-appointment-btn">
              <Link
                className="button-tile-link"
                to={ScreenRoutes.REQUEST_APPOINTMENT}
              >
                <div className="button-container">
                  <div className="icon-container">
                    <ClockIcon noShadow />
                  </div>
                  <div
                    style={{ display: 'flex', flex: 1, alignItems: 'center' }}
                  >
                    <div>
                      <button className="btn">
                        Request appointment online
                      </button>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="content section">
        <div className="section-header mt-1">
          Private Prescriptions & Specialist Referrals
        </div>
        <div className="content">
          <div className="paragraph">
            Private prescription medication and open referral letters are
            subject to a consultation with the GP.
          </div>
          <br />
          <div className="paragraph">
            To find out more tap on the button below.
          </div>
          <div className="flex-center mt-2">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.healthhero.com/private-prescription-service/"
            >
              <button className="btn btn-primary-1 btn-bold">
                Prescriptions & Referrals
              </button>
            </a>
          </div>
        </div>
      </div>

      <div className="content section">
        <div className="section-header mt-2">Feedback</div>
        <div className="content">
          <div className="paragraph">
            How did you find the service? Let us know your experience of the GP
            service by completing a 1 minute survey.
          </div>
          <div className="flex-center mt-2 mb-2">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://medicalsolutions.typeform.com/to/GQU4Bc"
            >
              <button className="btn btn-primary-1 btn-bold">
                Leave Feedback
              </button>
            </a>
          </div>
          <div className="paragraph terms-and-conditions">
            By using this service you are agreeing to our{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.healthhero.com/terms-and-conditions/"
            >
              terms and conditions
            </a>
            .{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.healthhero.com/privacy-policy/"
            >
              Click here for our privacy policy
            </a>
          </div>
        </div>
      </div>
    </div>
  )
})

import React, { memo } from 'react'
import { Allergy } from '../../../db/interfaces/Allergy'
import { ConfirmButton } from '../../../components/ConfirmButton'

export interface AllergyListProps {
  allergies: Allergy[]
  onDelete: (id: string | number) => void | Promise<void>
  onEdit: (id: number) => void
}

export const AllergyList = memo<AllergyListProps>(function AllergyList(props) {
  const { allergies, onEdit, onDelete } = props
  return (
    <div className="allergies-list list">
      {allergies.map(allergy => (
        <div key={`allergy-${allergy.id}`} className="box">
          <div className="box-row box-row-header">
            <div className="category box-cell">{allergy.allergy}</div>
          </div>
          <div className="box-row">
            <div className="title box-cell"> Year: </div>
            <div className="value box-cell">{allergy.year}</div>
          </div>
          <div className="box-row">
            <div className="box-cell title"> Description: </div>
            <div className="box-cell value">{allergy.description}</div>
          </div>

          <div className="box-row actions">
            <div className="box-cell">
              <button
                onClick={() => onEdit(allergy.id)}
                className="btn flat btn-primary-2-inversed w-full action-edit"
              >
                Edit
              </button>
            </div>
            <div className="box-cell">
              <ConfirmButton
                onConfirm={() => onDelete(allergy.id)}
                className="btn flat btn-primary-2-inversed w-full action-delete"
              >
                Delete
              </ConfirmButton>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
})

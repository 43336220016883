import React, { memo } from 'react'

import { AllergiesContent } from './AllergiesAndMedicines/AllergiesContent'
import { MedicinesContent } from './AllergiesAndMedicines/MedicinesContent'

export const AllergiesAndMedicines = memo(function AllergiesAndMedicines() {
  return (
    <div>
      <AllergiesContent />
      <MedicinesContent />
    </div>
  )
})

import React, { memo } from 'react'
import cx from 'clsx'
import { IconProps } from './IconProps'

export const ChevronCircleIcon = memo<IconProps>(props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    className={cx('chevron-circle-icon', props.className)}
  >
    <g>
      <g transform="matrix(-1 0 0 1 24 0)">
        <g>
          <path
            fill="#fff"
            d="M11.774 23.552h-.002a11.704 11.704 0 0 1-8.33-3.449c-4.59-4.592-4.59-12.063.002-16.655A11.698 11.698 0 0 1 11.77 0c3.146 0 6.104 1.225 8.326 3.448a11.699 11.699 0 0 1 3.451 8.328c0 3.147-1.224 6.104-3.449 8.328a11.692 11.692 0 0 1-8.324 3.448M11.77 2a9.715 9.715 0 0 0-6.912 2.862c-3.813 3.812-3.813 10.015-.002 13.826a9.721 9.721 0 0 0 6.916 2.864h.002a9.706 9.706 0 0 0 6.91-2.862 9.713 9.713 0 0 0 2.863-6.914 9.71 9.71 0 0 0-2.865-6.914A9.715 9.715 0 0 0 11.77 2"
          />
        </g>
        <g>
          <path
            fill="#fff"
            d="M14.192 17.62a.997.997 0 0 1-.707-.293l-4.842-4.84a1.003 1.003 0 0 1 0-1.414l4.842-4.84a.999.999 0 1 1 1.414 1.414l-4.135 4.133 4.135 4.133a.999.999 0 0 1-.707 1.707"
          />
        </g>
      </g>
    </g>
  </svg>
))

import React, { memo, ReactNode } from 'react'
import cx from 'clsx'

export interface AccordionHeaderProps {
  iconComponent: React.FC | React.ComponentClass | React.NamedExoticComponent
  className?: string
  children?: ReactNode
  onClick: () => void
}

export const AccordionHeader = memo<AccordionHeaderProps>(
  function AccordionHeader(props) {
    const { iconComponent, className, onClick, children } = props
    const Icon = iconComponent

    return (
      <div onClick={onClick} className={cx('accordion-header', className)}>
        <div className="accordion-header-title">{children}</div>
        <div className="accordion-header-icon">
          <Icon />
        </div>
      </div>
    )
  },
)

import React, { memo } from 'react'
import { Medicine } from '../../../db/interfaces/Medicine'
import { ConfirmButton } from '../../../components/ConfirmButton'

export interface MedicineListProps {
  medicines: Medicine[]
  onDelete: (id: string | number) => void | Promise<void>
  onEdit: (id: number) => void
}
export const MedicineList = memo<MedicineListProps>(function MedicineList(
  props,
) {
  const { medicines, onEdit, onDelete } = props
  return (
    <div className="medicine-list list">
      {medicines.map(medicine => (
        <div key={`medicine-${medicine.id}`} className="box">
          <div className="box-row box-row-header">
            <div className="category box-cell">{medicine.medicine}</div>
          </div>
          <div className="box-row">
            <div className="title box-cell"> Year: </div>
            <div className="value box-cell">{medicine.year}</div>
          </div>
          <div className="box-row">
            <div className="box-cell title"> Description: </div>
            <div className="box-cell value">{medicine.description}</div>
          </div>

          <div className="box-row actions">
            <div className="box-cell">
              <button
                onClick={() => onEdit(medicine.id)}
                className="btn flat btn-primary-2-inversed w-full action-edit"
              >
                Edit
              </button>
            </div>
            <div className="box-cell">
              <ConfirmButton
                onConfirm={() => onDelete(medicine.id)}
                className="btn flat btn-primary-2-inversed w-full action-delete"
              >
                Delete
              </ConfirmButton>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
})

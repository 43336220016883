import React, { memo } from 'react'
import cx from 'clsx'
import { IconProps } from './IconProps'

export const PaperPlaneIcon = memo<IconProps>(props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 40"
    className={cx('paper-plane-icon', props.className)}
  >
    <g transform="rotate(-41 17 14.5)">
      <path
        className="fg"
        d="M14.99 9.466h12.715L12.57 6.53 9.4 9.466h2.975c.352 0 .638.286.638.64h1.34c0-.354.285-.64.637-.64z"
      />

      <path
        className="fg"
        d="M27.705 10.74H14.99a.639.639 0 0 1-.638-.64h-1.339c0 .354-.286.64-.638.64H9.4l3.17 2.936z"
      />

      <path className="bg" d="M12.88 14.917l-2.09 7.324L30.44 11.51z" />

      <path className="bg" d="M30.44 8.69L10.79-2.04l2.09 7.324z" />

      <path
        fill="#54585a"
        d="M12.88 14.923l17.562-3.406-19.65 10.73zm17.562-6.23L12.88 5.286l-2.089-7.324zm-18.068 2.052a.639.639 0 0 0 0-1.28H9.4l3.17-2.936 15.134 2.936H14.99a.639.639 0 0 0 0 1.28h12.714L12.57 13.68 9.4 10.745h2.974zm22.62-.558l.002-.015v-.006l.002-.025.002-.034-.002-.038-.001-.025v-.006l-.003-.015-.002-.015a.37.37 0 0 0-.002-.01c0-.005 0-.01-.002-.015l-.002-.009-.007-.03-.004-.016-.003-.01-.002-.005-.005-.015a.64.64 0 0 0-.009-.024l-.006-.016c-.004-.011-.01-.022-.015-.032l-.012-.026-.008-.014-.01-.016a.659.659 0 0 0-.037-.054l-.008-.01a.649.649 0 0 0-.047-.054l-.005-.004-.01-.01a.634.634 0 0 0-.113-.084c-.007-.004-.013-.009-.02-.012L10.064-3.892a.637.637 0 0 0-.92.738l2.509 8.791-4.318 3.998-.008.008a.653.653 0 0 0-.026.027l-.018.02a.603.603 0 0 0-.024.03l-.015.02a.656.656 0 0 0-.023.037l-.01.016a.659.659 0 0 0-.023.046l-.005.011a.634.634 0 0 0-.02.055l-.003.005a.623.623 0 0 0-.016.327c.004.021.01.042.016.063l.002.005a.636.636 0 0 0 .02.055l.007.011.022.045.01.017a.612.612 0 0 0 .024.037l.014.02.024.03.018.02a.66.66 0 0 0 .026.027l.008.008 4.318 3.998-2.508 8.791a.64.64 0 0 0 .919.738L34.661 10.67l.011-.007.023-.013.026-.017.012-.009a.633.633 0 0 0 .049-.038l.023-.02.004-.005.014-.015a.69.69 0 0 0 .026-.029l.005-.005.005-.007.007-.01a.683.683 0 0 0 .031-.043l.013-.02.006-.011.012-.021a.64.64 0 0 0 .014-.03l.011-.025.01-.03.002-.003.006-.015.002-.01.003-.009.002-.01.002-.006.007-.027v-.006l.003-.017.002-.01.002-.015z"
      />
    </g>
    <path
      fillOpacity=".2"
      d="M15.435 39.459c8.115 0 14.694-1.603 14.694-3.58 0-1.976-6.579-3.579-14.694-3.579C7.319 32.3.74 33.903.74 35.88c0 1.976 6.579 3.579 14.695 3.579z"
    />
  </svg>
))

import React, { memo, SelectHTMLAttributes } from 'react'
import cx from 'clsx'

const alnum = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
function randomString(size: number): string {
  let output = ''

  for (let i = 0; i < size; i++) {
    output += alnum.charAt(Math.floor(Math.random() * alnum.length))
  }

  return output
}

export interface NativeSelectOptions {
  value: any
  label: string
}

export interface NativeSelectProps
  extends SelectHTMLAttributes<HTMLSelectElement> {
  options: NativeSelectOptions[]
  label: string
  selectRef?: any
}

export const NativeSelect = memo<NativeSelectProps>(function NativeSelect(
  props,
) {
  const { options, className, label, selectRef, ...selectProps } = props
  const nonce = randomString(8)
  const classes = className
  return (
    <div className="form-control">
      <label>{label}</label>
      <div className="native-select-container">
        <select ref={selectRef} {...selectProps} className={classes}>
          {options.map(opt => (
            <option key={`${opt.value}-${nonce}`} value={opt.value}>
              {opt.label}
            </option>
          ))}
        </select>
      </div>
    </div>
  )
})

export default NativeSelect

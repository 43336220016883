import React, { memo } from 'react'
import { HeartRate } from '../../../../db/interfaces/HeartRate'
import { ConfirmButton } from '../../../../components/ConfirmButton'

export interface HeartRateListProps {
  rates: HeartRate[]
  onDelete: (id: string | number) => void | Promise<void>
  onEdit: (id: number) => void
}

export const HeartRateList = memo<HeartRateListProps>(function HeartRateList(
  props,
) {
  const { rates, onDelete, onEdit } = props
  return (
    <div className="heart-rate-list list">
      {rates.map(rate => (
        <div className="box">
          <div className="box-row">
            <div className="title box-cell">Date: </div>
            <div className="value box-cell">{rate.date}</div>
          </div>
          <div className="box-row">
            <div className="title box-cell"> Time: </div>
            <div className="value box-cell">{rate.time}</div>
          </div>
          <div className="box-row">
            <div className="box-cell title"> Beats per Minute: </div>
            <div className="box-cell value">{rate.bpm}</div>
          </div>
          <div className="box-row actions">
            <div className="box-cell">
              <button
                onClick={() => onEdit(rate.id)}
                className="btn flat btn-primary-2-inversed w-full action-edit"
              >
                Edit
              </button>
            </div>
            <div className="box-cell">
              <ConfirmButton
                onConfirm={() => onDelete(rate.id)}
                className="btn flat btn-primary-2-inversed w-full action-delete"
              >
                Delete
              </ConfirmButton>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
})

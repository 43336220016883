import React, { memo } from 'react'
import { useForm } from 'react-hook-form'

import { TextInput } from '../../../components/form/TextInput'
import {
  NativeSelectOptions,
  NativeSelect,
} from '../../../components/form/NativeSelect'
import { Consultation } from '../../../db/interfaces/Consultation'

export interface ConsultationsFormProps {
  onSubmit: (values?: any) => void
  onCancel: () => void
  consultation?: Consultation
}

const categories: NativeSelectOptions[] = [
  {
    value: '',
    label: 'Select Main Category',
  },
  {
    value: 'ENT',
    label: 'ENT',
  },
  {
    value: 'Orthopeadics',
    label: 'Orthopeadics',
  },
  {
    value: 'Respiratory',
    label: 'Respiratory',
  },
  {
    value: 'Gastroenterology',
    label: 'Gastroenterology',
  },
  {
    value: 'URTI',
    label: 'URTI',
  },
  {
    value: 'Dermatology',
    label: 'Dermatology',
  },
  {
    value: 'Musculoskeletal',
    label: 'Musculoskeletal',
  },
]

export const ConsultationsForm = memo<ConsultationsFormProps>(
  function ConsultationsForm(props) {
    const { onSubmit, onCancel, consultation } = props
    const { register, handleSubmit } = useForm<Consultation>({
      defaultValues: consultation,
    })
    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form">
          <TextInput
            inputRef={register}
            name="consultationDate"
            type="date"
            label="Consultation date"
          />
          <TextInput
            inputRef={register}
            name="consultationTime"
            type="time"
            label="Consultation time"
          />
          <NativeSelect
            selectRef={register}
            name="mainCategory"
            label="Main category"
            options={categories}
            defaultValue={''}
          />
          <TextInput
            inputRef={register}
            name="outcome"
            label="Outcome of consultation"
          />
          <TextInput
            inputRef={register}
            name="clinicalCategory1"
            label="Clinical category (1)"
          />
          <TextInput
            inputRef={register}
            name="clinicalCategory2"
            label="Clinical category (2)"
          />
          <TextInput
            inputRef={register}
            name="clinicalCategory3"
            label="Clinical category (3)"
          />
          {consultation ? (
            <input ref={register} name="id" type="hidden" />
          ) : null}

          <div className="button-container">
            <div className="form-control">
              <button type="submit" className="btn btn-primary-2 w-full">
                {consultation ? 'Edit' : 'Add'}
              </button>
            </div>
            <div className="form-control">
              <button
                onClick={e => {
                  e.preventDefault()
                  onCancel()
                }}
                className="btn w-full"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </form>
    )
  },
)

import React, { memo } from 'react'
import Modal from 'react-modal'
import { BloodPressureForm } from './BloodPressureForm'

export interface NewBloodPressureModalProps {
  isOpen: boolean
  onSubmit: (values: any) => void
  onCancel: () => void
}

export const NewBloodPressureModal = memo<NewBloodPressureModalProps>(
  function NewBloodPressureModal(props) {
    const { isOpen, onSubmit, onCancel } = props
    return (
      <Modal isOpen={isOpen}>
        <div className="modal-header">
          <div style={{ padding: 0 }} className="modal-title">
            New Blood Pressure
          </div>
          <div className="modal-subtitle">
            Enter a new blood pressure in this form
          </div>
        </div>

        <BloodPressureForm onSubmit={onSubmit} onCancel={onCancel} />
      </Modal>
    )
  },
)

import React, { memo, useState, ReactNode } from 'react'

export interface ListToggleProps {
  recordsCount: number
  children?: ReactNode
}

export const ListToggle = memo<ListToggleProps>(function ListToggle(props) {
  const [show, setShow] = useState(false)
  const toggle = () => setShow(!show)
  const { recordsCount, children } = props

  const text = recordsCount === 1 ? ' record' : 'records'
  return (
    <div>
      <div className="list-toggle-head mb-1">
        {recordsCount} {text}
        <button
          disabled={recordsCount === 0}
          onClick={toggle}
          className="btn flat ml-1 btn-link"
        >
          {show ? 'Hide' : 'Show'}
        </button>
      </div>
      {show ? children : null}
    </div>
  )
})

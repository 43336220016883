import React, { memo } from 'react'
import Modal from 'react-modal'
import { HeartRateForm } from './HeartRateForm'
import { HeartRate } from '../../../../db/interfaces/HeartRate'

export interface EditHeartRateModalProps {
  isOpen: boolean
  onSubmit: (values: any) => void
  onCancel: () => void
  rate?: HeartRate
}
export const EditHeartRateModal = memo<EditHeartRateModalProps>(
  function EditHeartRateModal(props) {
    const { isOpen, onSubmit, onCancel, rate } = props
    return (
      <Modal isOpen={isOpen}>
        <div className="modal-header">
          <div style={{ padding: 0 }} className="modal-title">
            Edit Heart Rate
          </div>
          <div className="modal-subtitle">Edit heart rate in this form</div>
        </div>

        <HeartRateForm
          heartRate={rate}
          onSubmit={onSubmit}
          onCancel={onCancel}
        />
      </Modal>
    )
  },
)

import React, { memo } from 'react'
import { IconProps } from './IconProps'

export const MedicalHistoryIcon = memo<IconProps>(function HeartbeatIcon(
  props,
) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 35 45"
      className={props.className}
    >
      <path
        fillOpacity=".2"
        d="M17.5 45c9.665 0 17.5-2.686 17.5-6s-7.835-6-17.5-6S0 35.686 0 39s7.835 6 17.5 6z"
      />
      {/* <path fill="#002e62" d="M25.014 7H31v32H6V7h5.986" /> */}
      <path
        className="fg"
        d="M30.948 40H6.052C5.47 40 5 39.514 5 38.915V7.085C5 6.485 5.47 6 6.052 6h5.961c.581 0 1.052.486 1.052 1.085 0 .6-.471 1.085-1.052 1.085h-4.91v29.66h22.793V8.17h-4.909c-.581 0-1.052-.486-1.052-1.085 0-.6.471-1.085 1.052-1.085h5.961C31.53 6 32 6.486 32 7.085v31.83c0 .6-.471 1.085-1.052 1.085z"
      />
      {/* <path fill="#002e62" d="M20.698 35H9V10h19v18.306z" /> */}
      <path
        className="fg"
        d="M9 10h18v18.328L19.963 35H9zm11.63 25.806l7.147-6.844a.728.728 0 0 0 .223-.526V9.72a.711.711 0 0 0-.702-.72H8.702A.711.711 0 0 0 8 9.72v25.56c0 .397.314.72.702.72h11.45c.177 0 .348-.07.479-.194z"
      />
      <path
        className="fg"
        d="M19.696 35a.696.696 0 0 1-.696-.696v-6.608c0-.384.311-.696.696-.696h6.608a.696.696 0 0 1 0 1.391h-5.913v5.913a.696.696 0 0 1-.695.696z"
      />
      <path
        className="fg"
        d="M23.902 10c-.606 0-1.097-.474-1.097-1.059V6.118h-10.61V8.94c0 .585-.491 1.059-1.097 1.059C10.49 10 10 9.526 10 8.941V5.06C10 4.474 10.491 4 11.098 4h12.804C24.51 4 25 4.474 25 5.059V8.94C25 9.526 24.508 10 23.902 10z"
      />
      <path
        className="fg"
        d="M24.247 10H11.753c-.416 0-.753-.276-.753-.615v-2.77c0-.34.337-.615.753-.615.415 0 .752.276.752.615V8.77h10.99V6.615c0-.34.337-.615.752-.615.416 0 .753.276.753.615v2.77c0 .34-.337.615-.753.615z"
      />
      <path
        className="fg"
        d="M19.957 6c-.577 0-1.044-.556-1.044-1.241 0-1.255-.858-2.276-1.913-2.276-1.055 0-1.913 1.021-1.913 2.276 0 .685-.467 1.241-1.044 1.241C13.467 6 13 5.444 13 4.759 13 2.135 14.794 0 17 0s4 2.135 4 4.759C21 5.444 20.533 6 19.957 6z"
      />
      <path
        className="fg"
        d="M18 19.833c0 .642-.45 1.167-1 1.167s-1-.525-1-1.167v-4.666c0-.642.45-1.167 1-1.167s1 .525 1 1.167z"
      />
      <path
        className="fg"
        d="M14.167 19C13.525 19 13 18.55 13 18s.525-1 1.167-1h4.666c.642 0 1.167.45 1.167 1s-.525 1-1.167 1z"
      />
      <path
        className="fg"
        d="M18.304 25h-6.608c-.385 0-.696-.224-.696-.5s.311-.5.696-.5h6.608c.384 0 .696.224.696.5s-.312.5-.696.5z"
      />
      <path
        className="fg"
        d="M23.333 25h-1.666c-.369 0-.667-.224-.667-.5s.298-.5.667-.5h1.666c.368 0 .667.224.667.5s-.299.5-.667.5z"
      />
      <path
        className="fg"
        d="M16.28 28h-4.56c-.398 0-.72-.224-.72-.5s.322-.5.72-.5h4.56c.397 0 .72.224.72.5s-.322.5-.72.5z"
      />
    </svg>
  )
})

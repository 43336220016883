import React, { memo, useState } from 'react'

import { useAction } from '../../../../util/useAction'
import { ActionTypes } from '../../../../state/actions'
import { useDbSelector } from '../../../../util/useDbSelector'
import { HeartRateCollection } from '../../../../db/heartRate'
import { HeartRate } from '../../../../db/interfaces/HeartRate'
import { HeartRateList } from './HeartRateList'
import { ListToggle } from '../../ListToggle'
import { EditHeartRateModal } from './EditHeartRateModal'
import { NewHeartRateModal } from './NewHeartRateModal'

export const HeartRateContent = memo(function HeartRateContent() {
  const [showModalNew, setShowModalNew] = useState(false)
  const hideModalNew = () => setShowModalNew(false)

  const [showModalEdit, setShowModalEdit] = useState(false)
  const hideModalEdit = () => setShowModalEdit(false)

  const [rateId, setRateId] = useState(-1)
  const saveHeartRate = useAction(ActionTypes.HEART_RATE_SAVE)

  const rates = useDbSelector<HeartRate>(HeartRateCollection)

  const rate = rates.find(r => r.id === rateId)

  const onEdit = (id: number) => {
    setRateId(id)
    setShowModalEdit(true)
  }

  const onSubmitNew = (rate: Partial<HeartRate>) => {
    saveHeartRate(rate)
    hideModalNew()
  }

  const onSubmitEdit = (rate: Partial<HeartRate>) => {
    saveHeartRate(rate)
    hideModalEdit()
  }

  const deleteRecord = (id: number | string) => HeartRateCollection.delete(id)

  return (
    <div>
      <div className="health-stats-top-bar">
        <div className="stat-description">Heart rate</div>
        <button onClick={() => setShowModalNew(true)} className="btn">
          Add
        </button>
      </div>
      <EditHeartRateModal
        isOpen={showModalEdit}
        rate={rate}
        onCancel={hideModalEdit}
        onSubmit={onSubmitEdit}
      />
      <NewHeartRateModal
        isOpen={showModalNew}
        onSubmit={onSubmitNew}
        onCancel={hideModalNew}
      />
      <ListToggle recordsCount={rates.length}>
        <HeartRateList onDelete={deleteRecord} onEdit={onEdit} rates={rates} />
      </ListToggle>
    </div>
  )
})

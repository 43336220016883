export const loadReCaptcha = (siteKey: string) => {
  // @ts-ignore
  if (window.grecaptcha) {
    return
  }

  const script = document.createElement('script')

  script.src = `https://www.google.com/recaptcha/api.js?render=${siteKey}`

  document.body.appendChild(script)
}

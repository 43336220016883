import React, { memo } from 'react'
import Modal from 'react-modal'
import { ConsultationsForm } from './ConsultationsForm'
import { Consultation } from '../../../db/interfaces/Consultation'

export interface EditConsultationModalProps {
  isOpen: boolean
  onSubmit: (values: any) => void
  onCancel: () => void
  consultation?: Consultation
}
export const EditConsultationModal = memo<EditConsultationModalProps>(
  function EditConsultationModal(props) {
    const { isOpen, onSubmit, onCancel, consultation } = props
    return (
      <Modal isOpen={isOpen}>
        <div className="modal-header">
          <div style={{ padding: 0 }} className="modal-title">
            Edit Consultation
          </div>
          <div className="modal-subtitle">Edit consultation in this form</div>
        </div>

        <ConsultationsForm
          consultation={consultation}
          onSubmit={onSubmit}
          onCancel={onCancel}
        />
      </Modal>
    )
  },
)

import React, { memo } from 'react'
import Modal from 'react-modal'
import { MedicineForm } from './MedicineForm'

export interface NewMedicineModalProps {
  isOpen: boolean
  onSubmit: (values: any) => void
  onCancel: () => void
}
export const NewMedicineModal = memo<NewMedicineModalProps>(
  function NewMedicineModal(props) {
    const { isOpen, onSubmit, onCancel } = props
    return (
      <Modal isOpen={isOpen}>
        <div className="modal-header">
          <div style={{ padding: 0 }} className="modal-title">
            New Medicine
          </div>
          <div className="modal-subtitle">
            Enter a new medicine in this form
          </div>
        </div>

        <MedicineForm onSubmit={onSubmit} onCancel={onCancel} />
      </Modal>
    )
  },
)

import React, { memo, useState, ReactNode } from 'react'

export interface ConfirmButtonProps {
  onConfirm: (...args: any[]) => any
  className?: string
  children?: ReactNode
}

export const ConfirmButton = memo<ConfirmButtonProps>(function ConfirmButton(
  props,
) {
  const { onConfirm, className, children } = props
  const [showConfirm, setShowConfirm] = useState(false)

  const toggleConfirm = () => setShowConfirm(!showConfirm)

  const doAction = () => {
    onConfirm()
    toggleConfirm()
  }

  return showConfirm ? (
    <div className="confirm">
      <div className="confirm-btn-container">
        <a onClick={doAction} className="confirm-yes">
          Delete
        </a>
        <a onClick={toggleConfirm} className="confirm-no">
          Cancel
        </a>
      </div>
    </div>
  ) : (
    <button onClick={toggleConfirm} className={className}>
      {children}
    </button>
  )
})

import React, { memo } from 'react'
import Modal from 'react-modal'
import { MedicineForm } from './MedicineForm'
import { Medicine } from '../../../db/interfaces/Medicine'

export interface EditMedicineyModalProps {
  isOpen: boolean
  onSubmit: (values: any) => void
  onCancel: () => void
  medicine?: Medicine
}
export const EditMedicineModal = memo<EditMedicineyModalProps>(
  function EditMedicineyModal(props) {
    const { isOpen, onSubmit, onCancel, medicine } = props
    return (
      <Modal isOpen={isOpen}>
        <div className="modal-header">
          <div style={{ padding: 0 }} className="modal-title">
            Edit Medicine
          </div>
          <div className="modal-subtitle">Edit medicine in this form</div>
        </div>

        <MedicineForm
          medicine={medicine}
          onSubmit={onSubmit}
          onCancel={onCancel}
        />
      </Modal>
    )
  },
)

import React, { memo } from 'react'

export interface HeaderBgProps {
  className?: string
  pathProps?: React.SVGProps<SVGPathElement>
  dropShadow?: boolean
}

const defaultPathProps: React.SVGProps<SVGPathElement> = {}

const DropShadow = () => (
  <filter id="header-shadow" height="130%">
    <feGaussianBlur in="SourceAlpha" stdDeviation="3" />
    <feOffset dx="5" dy="5" result="offsetblur" />
    <feComponentTransfer>
      <feFuncA type="linear" slope="0.5" />
    </feComponentTransfer>
    <feMerge>
      <feMergeNode />
      <feMergeNode in="SourceGraphic" />
    </feMerge>
  </filter>
)

export const HeaderBg = memo<HeaderBgProps>(function HeaderBg(props) {
  const { pathProps = defaultPathProps, dropShadow } = props
  const _pathProps = {
    ...pathProps,
  }
  if (dropShadow) {
    _pathProps.style = Object.assign(pathProps.style || {}, {
      filter: 'url(#header-shadow)',
    })
  }
  return (
    <svg
      viewBox="0 0 2880 698"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
      preserveAspectRatio="none"
    >
      <defs>
        <clipPath id="cut-off">
          <rect x="50" y="0" width="2880" height="698" />
        </clipPath>
      </defs>
      {dropShadow ? <DropShadow /> : null}
      <g transform="translate(-416, -12)" clipPath="url(#cut-off)">
        <path
          {..._pathProps}
          d="M259.121,218.99 C216.77,413.835 1810.85,806.219 3042.178,688.324 C4273.506,570.429 3196.494,12.044 3196.494,12.044 C3196.494,12.044 469.216,-14.042 259.121,12.044 C-328.357,84.99 268.083,177.764 259.121,218.992 L259.121,218.99 Z"
          className="bg"
        />
      </g>
    </svg>
  )
})

import { useState } from 'react'

export interface UseAccordionParams {
  multiple?: boolean
  panes: string[]
}

export const useAccordion = (params: UseAccordionParams) => {
  const [selection, setSelection] = useState(new Set<string>())

  const isExpanded = (pane: string) => selection.has(pane)

  const toggle = (pane: string) => {
    const newSelection: Set<string> = new Set([...Array.from(selection)])

    if (params.multiple) {
      isExpanded(pane) ? newSelection.delete(pane) : newSelection.add(pane)
    } else {
      newSelection.clear()
      if (!isExpanded(pane)) {
        newSelection.add(pane)
      }
    }

    setSelection(newSelection)
  }

  return {
    isExpanded,
    toggle,
  }
}

import React, { memo } from 'react'
import { BloodGlucose } from '../../../../db/interfaces/BloodGlucose'
import { ConfirmButton } from '../../../../components/ConfirmButton'

export interface BloodGlucoseListProps {
  glucoseRecords: BloodGlucose[]
  onDelete: (id: string | number) => void | Promise<void>
  onEdit: (id: number) => void
}

export const BloodGlucoseList = memo<BloodGlucoseListProps>(
  function BloodGlucoseList(props) {
    const { glucoseRecords, onDelete, onEdit } = props
    return (
      <div className="blood-glucose-list list">
        {glucoseRecords.map(glucose => (
          <div className="box">
            <div className="box-row">
              <div className="title box-cell">Date: </div>
              <div className="value box-cell">{glucose.date}</div>
            </div>
            <div className="box-row">
              <div className="title box-cell"> Time: </div>
              <div className="value box-cell">{glucose.time}</div>
            </div>
            <div className="box-row">
              <div className="box-cell title"> Glucose: </div>
              <div className="box-cell value">{glucose.glucose}</div>
            </div>
            <div className="box-row actions">
              <div className="box-cell">
                <button
                  onClick={() => onEdit(glucose.id)}
                  className="btn flat btn-primary-2-inversed w-full action-edit"
                >
                  Edit
                </button>
              </div>
              <div className="box-cell">
                <ConfirmButton
                  onConfirm={() => onDelete(glucose.id)}
                  className="btn flat btn-primary-2-inversed w-full action-delete"
                >
                  Delete
                </ConfirmButton>
              </div>
            </div>
          </div>
        ))}
      </div>
    )
  },
)

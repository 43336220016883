import React, { memo } from 'react'
import cx from 'clsx'

import { IconProps } from './IconProps'

export const PhoneConsultationIcon = memo<IconProps>(props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 39"
    className={cx('phone-consultation-icon', props.className)}
  >
    <path
      className="fg"
      d="M22.697 38.882c-6.053 0-12.405-3.368-16.993-9.008C1.461 24.657-.613 18.168.158 12.516a.566.566 0 0 1 .181-.343c.07-.063.234-.22.444-.42 1.014-.962 1.605-1.512 1.825-1.66a8.048 8.048 0 0 1 2.269-1.06c.236-.07.49.023.628.227l6.458 9.578a.567.567 0 0 1-.025.668 8.074 8.074 0 0 1-3.738 2.65.26.26 0 0 0 .064.257l.054.054c.241.244 1.286 1.3 1.867 1.881.553.553 3.593 3.58 5.115 5.096l.872.87a.262.262 0 0 0 .185.074.273.273 0 0 0 .11-.023 8.053 8.053 0 0 1 2.634-3.696.567.567 0 0 1 .668-.025l9.58 6.46a.567.567 0 0 1 .228.628 8.068 8.068 0 0 1-1.062 2.27c-.161.237-.77.892-1.767 1.941l-.357.377a.566.566 0 0 1-.284.167 14.562 14.562 0 0 1-3.41.395M1.257 12.869c-.654 5.3 1.328 11.372 5.328 16.289 4.374 5.378 10.398 8.59 16.111 8.59 1.03 0 2.031-.11 2.982-.326l.247-.26c.412-.434 1.508-1.588 1.649-1.795.327-.486.592-1.01.787-1.56l-8.873-5.984a6.922 6.922 0 0 0-2.001 3.07.566.566 0 0 1-.222.296 1.389 1.389 0 0 1-.908.334c-.374 0-.725-.146-.99-.41l-.868-.865c-1.522-1.516-4.563-4.545-5.116-5.098-.583-.582-1.63-1.64-1.872-1.885l-.05-.05a1.4 1.4 0 0 1-.06-1.91.57.57 0 0 1 .258-.167 6.922 6.922 0 0 0 3.125-2.02l-5.982-8.87a6.906 6.906 0 0 0-1.56.787c-.192.131-1.233 1.12-1.677 1.542l-.308.292"
    />
    <path
      className="fg"
      d="M27.56 36.2a.563.563 0 0 1-.318-.098l-9.743-6.615a.567.567 0 1 1 .637-.939l9.743 6.616a.567.567 0 0 1-.319 1.037"
    />
    <path
      className="fg"
      d="M9.474 21.248a.567.567 0 0 1-.47-.249l-6.616-9.743a.567.567 0 0 1 .939-.637l6.616 9.743a.567.567 0 0 1-.47.886"
    />
    <path
      className="fg"
      d="M29.242 21.747l-.165-.003a.627.627 0 0 1-.066.003H17.918a.568.568 0 0 1-.198-1.1 5.184 5.184 0 0 0 3.043-3.052 10.755 10.755 0 0 1-2.254-6.581C18.509 5.094 23.324.28 29.242.28c5.92 0 10.735 4.815 10.735 10.734 0 5.918-4.816 10.733-10.735 10.733M29 20.607a.2.2 0 0 1 .014 0l.09.002.138.003c5.293 0 9.6-4.305 9.6-9.598 0-5.293-4.307-9.6-9.6-9.6-5.292 0-9.598 4.307-9.598 9.6 0 2.225.779 4.394 2.193 6.107.12.147.16.345.105.526a6.318 6.318 0 0 1-1.954 2.965h8.93a.565.565 0 0 1 .082-.005"
    />
    <path
      className="fg"
      d="M27.052 16.724a.568.568 0 0 1-.568-.568V13.32h-2.837a.568.568 0 0 1-.567-.568v-3.12c0-.314.254-.568.567-.568h2.837V6.227c0-.313.254-.567.568-.567h3.12c.313 0 .568.254.568.567v2.837h2.836c.314 0 .568.254.568.568v3.12a.568.568 0 0 1-.568.568H30.74v2.836a.568.568 0 0 1-.568.568zm.571-1.126h1.988v-2.84c0-.313.254-.567.568-.567h2.84v-1.988h-2.84a.568.568 0 0 1-.568-.568v-2.84h-1.988v2.84a.568.568 0 0 1-.568.568h-2.84v1.988h2.84c.314 0 .568.254.568.568z"
    />
  </svg>
))

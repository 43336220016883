import React, { memo, useState } from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import 'react-tabs/style/react-tabs.css'

import { HeartbeatIcon } from '../../components/icons/HeartbeatIcon'
import { PageHeader } from '../../components/app/PageHeader'
import { useAccordion } from '../../components/accordion/useAccordion'
import { AccordionPane } from '../../components/accordion/AccordionPane'
import { ExpandedIcon } from '../../components/icons/ExpandedIcon'
import { CollapsedIcon } from '../../components/icons/CollapsedIcon'

import allergiesIconSrc from '../../assets/images/allergies-icon.svg'
import consultationsIconSrc from '../../assets/images/consultations-icon.svg'
import healthStatsIconSrc from '../../assets/images/health-statistics-icon.svg'
import compassIconSrc from '../../assets/images/compass-icon.svg'
import { Consultations } from './Consultations'
import { AllergiesAndMedicines } from './AllergiesAndMedicines'
import { HealthStatistics } from './HealthStatistics'
import { Contacts } from './Contacts'
import { Link } from 'react-router-dom'
import { ScreenRoutes } from '../../types/ScreenRoutes'

type Panes = 'consultations' | 'allergiesAndMedicines' | 'healthStatistics'

export const MyHealthPage = memo(function MyHealthPage() {
  const panes = {
    consultations: {
      title: (
        <>
          <img
            className="accordion-title-icon"
            src={consultationsIconSrc}
            alt="consultations pane icon"
          />
          <span>Consultations</span>
        </>
      ),
      className: 'consultations',
      headerClassName: 'consultations-pane-header',
      render: () => <Consultations />,
    },
    allergiesAndMedicines: {
      title: (
        <>
          <img
            className="accordion-title-icon"
            src={allergiesIconSrc}
            alt="consultations pane icon"
          />
          <span>Allergies & Medicines</span>
        </>
      ),
      className: 'allergies',
      headerClassName: 'allergies-pane-header',
      render: () => <AllergiesAndMedicines />,
    },
    healthStatistics: {
      title: (
        <>
          <img
            className="accordion-title-icon"
            src={healthStatsIconSrc}
            alt="consultations pane icon"
          />
          <span>Health Statistics</span>
        </>
      ),
      className: 'health-stats',
      headerClassName: 'health-stats-pane-header',
      render: () => <HealthStatistics />,
    },
  }
  const { isExpanded, toggle } = useAccordion({
    panes: Object.keys(panes),
  })

  const [tabIndex, setTabIndex] = useState(0)

  return (
    <div className="page my-health-page pattern-bg">
      <PageHeader
        title="My Health"
        icon={<HeartbeatIcon className="heartbeat-icon" />}
      />

      <div className="content section-header">
        Find your local healthcare services worldwide
      </div>
      <div className="content find-services-section">
        <img src={compassIconSrc} alt="find services icon" />
        <Link to={ScreenRoutes.SERVICES_NEAR_YOU}>
          <button className="btn find-services-btn">
            Find services near you
          </button>
        </Link>
      </div>

      <div className="notes-and-contacts-header section-header">
        Store your Medical Notes & Contacts
      </div>
      <div>
        <Tabs
          selectedIndex={tabIndex}
          onSelect={tabIndex => setTabIndex(tabIndex)}
        >
          <TabList>
            <Tab>
              <span className="tab-label">My Notes</span>
            </Tab>
            <Tab>
              <span className="tab-label">My Contacts</span>
            </Tab>
          </TabList>

          <TabPanel>
            {Object.keys(panes).map(pane => (
              <AccordionPane
                name={pane}
                className={panes[pane as Panes].className}
                expanded={isExpanded(pane)}
                // @ts-ignore
                title={panes[pane].title}
                headerProps={{
                  // @ts-ignore
                  className: panes[pane].headerClassName,
                  // @ts-ignore
                  iconComponent: isExpanded(pane)
                    ? ExpandedIcon
                    : CollapsedIcon,
                  // @ts-ignore
                  onClick: () => {
                    toggle(pane)
                  },
                }}
              >
                {/**
                // @ts-ignore */
                panes[pane].render()}
              </AccordionPane>
            ))}
          </TabPanel>
          <TabPanel>
            <Contacts />
          </TabPanel>
        </Tabs>
      </div>
    </div>
  )
})

import React, { memo } from 'react'
import cx from 'clsx'
import { IconProps } from './IconProps'

export const MessageIcon = memo<IconProps>(props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 35 34"
    className={cx('message-icon', props.className)}
  >
    <path
      className="fg"
      d="M18.406 33.597a8.24 8.24 0 0 1-.256-.005.968.968 0 0 1-.101.005H.888a.878.878 0 0 1-.306-1.7 8.02 8.02 0 0 0 4.707-4.723 16.637 16.637 0 0 1-3.487-10.18C1.802 7.837 9.251.39 18.406.39S35.01 7.838 35.01 16.993c0 9.156-7.449 16.604-16.604 16.604m-.376-1.81l.02.001.14.004c.07.003.142.005.213.005 8.163 0 14.804-6.64 14.804-14.804 0-8.163-6.641-14.803-14.804-14.803C10.241 2.19 3.6 8.83 3.6 16.993c0 3.432 1.201 6.777 3.382 9.42a.876.876 0 0 1 .162.811 9.745 9.745 0 0 1-3.013 4.573h13.772a.87.87 0 0 1 .127-.01"
    />
    <path
      className="fg"
      d="M16.184 25.26a.82.82 0 0 1-.82-.82v-4.103H11.26a.82.82 0 0 1-.821-.82v-4.513a.82.82 0 0 1 .82-.82h4.103V10.08a.82.82 0 0 1 .82-.821h4.513a.82.82 0 0 1 .82.82v4.103h4.103a.82.82 0 0 1 .821.82v4.513a.82.82 0 0 1-.82.82h-4.103v4.103a.82.82 0 0 1-.82.821zm.725-1.219h3.062v-4.375c0-.483.392-.875.875-.875h4.375V15.73h-4.375a.875.875 0 0 1-.875-.875v-4.375H16.91v4.375a.875.875 0 0 1-.875.875h-4.375v3.062h4.375c.483 0 .875.392.875.875z"
    />
  </svg>
))

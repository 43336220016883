export enum ScreenRoutes {
  HOME = '/',
  GP_CONSULT = '/gp-consultations',
  E_CONSULT = '/e-consultations',
  MESSAGE_DR = '/message-dr',
  MESSAGE_SENT = '/message-sent',
  REQUEST_APPOINTMENT = '/request-appointment',
  REQUEST_CONFIRMED = '/request-confirmed',
  MY_HEALTH = '/my-health',
  HEALTH_INFORMATION = '/health-information',
  SERVICES_NEAR_YOU = '/services-near-you',
}

import React, { memo, useState } from 'react'

import { Consultation } from '../../db/interfaces/Consultation'
import { useAction } from '../../util/useAction'
import { ActionTypes } from '../../state/actions'
import { useDbSelector } from '../../util/useDbSelector'
import { ConsultationCollection } from '../../db/consultations'
import { ConsultationsList } from './Consultations/ConsultationsList'
import { NewConsultationModal } from './Consultations/NewConsultationModal'
import { EditConsultationModal } from './Consultations/EditConsultationModal'

export const Consultations = memo(function Consultations() {
  const [showModalNew, setShowModalNew] = useState(false)
  const hideModalNew = () => setShowModalNew(false)

  const [showModalEdit, setShowModalEdit] = useState(false)
  const hideModalEdit = () => setShowModalEdit(false)

  const [consultationId, setConsultationId] = useState(-1)
  const saveConsultation = useAction(ActionTypes.CONSULTATION_SAVE)

  const consultations = useDbSelector<Consultation>(ConsultationCollection)

  const consultation = consultations.find(c => c.id === consultationId)

  const onEdit = (id: number) => {
    setConsultationId(id)
    setShowModalEdit(true)
  }

  const onSubmitNew = (consultation: Partial<Consultation>) => {
    saveConsultation(consultation)
    hideModalNew()
  }

  const onSubmitEdit = (consultation: Partial<Consultation>) => {
    saveConsultation(consultation)
    hideModalEdit()
  }

  const deleteRecord = (id: number | string) =>
    ConsultationCollection.delete(id)

  return (
    <div>
      <div>
        <button onClick={() => setShowModalNew(true)} className="btn">
          Enter consultation
        </button>
      </div>
      <NewConsultationModal
        isOpen={showModalNew}
        onCancel={hideModalNew}
        onSubmit={onSubmitNew}
      />
      <EditConsultationModal
        isOpen={showModalEdit}
        onCancel={hideModalEdit}
        onSubmit={onSubmitEdit}
        consultation={consultation}
      />
      <ConsultationsList
        onEdit={onEdit}
        onDelete={deleteRecord}
        consultations={consultations}
      />
    </div>
  )
})

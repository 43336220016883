import React, { memo, useState } from 'react'
import Modal from 'react-modal'

import { useAction } from '../../../../util/useAction'
import { ActionTypes } from '../../../../state/actions'
import { useDbSelector } from '../../../../util/useDbSelector'
import { BloodPressureCollection } from '../../../../db/bloodPressure'
import { BloodPressure } from '../../../../db/interfaces/BloodPressure'
import { BloodPressureList } from './BloodPressureList'
import { ListToggle } from '../../ListToggle'
import { EditBloodPressureModal } from './EditBloodPressureModal'
import { NewBloodPressureModal } from './NewBloodPressureModal'

export const BloodPressureContent = memo(function BloodGlucoseContent() {
  const [showModalNew, setShowModalNew] = useState(false)
  const hideModalNew = () => setShowModalNew(false)

  const [showModalEdit, setShowModalEdit] = useState(false)
  const hideModalEdit = () => setShowModalEdit(false)

  const [recordId, setRecordId] = useState(-1)

  const saveRecord = useAction(ActionTypes.BLOOD_PRESSURE_SAVE)

  const records = useDbSelector<BloodPressure>(BloodPressureCollection)

  const record = records.find(r => r.id === recordId)

  const onEdit = (id: number) => {
    setRecordId(id)
    setShowModalEdit(true)
  }

  const onSubmitNew = (record: Partial<BloodPressure>) => {
    saveRecord(record)
    hideModalNew()
  }

  const onSubmitEdit = (record: Partial<BloodPressure>) => {
    saveRecord(record)
    hideModalEdit()
  }

  const deleteRecord = (id: number | string) =>
    BloodPressureCollection.delete(id)

  return (
    <div>
      <div className="health-stats-top-bar">
        <div className="stat-description">Blood Pressure</div>
        <button onClick={() => setShowModalNew(true)} className="btn">
          Add
        </button>
      </div>

      <NewBloodPressureModal
        isOpen={showModalNew}
        onSubmit={onSubmitNew}
        onCancel={hideModalNew}
      />

      <EditBloodPressureModal
        isOpen={showModalEdit}
        onSubmit={onSubmitEdit}
        onCancel={hideModalEdit}
        pressure={record}
      />

      <ListToggle recordsCount={records.length}>
        <BloodPressureList
          pressureRecords={records}
          onDelete={deleteRecord}
          onEdit={onEdit}
        />
      </ListToggle>
    </div>
  )
})

import React, { memo } from 'react'
import Modal from 'react-modal'
import { BloodPressureForm } from './BloodPressureForm'
import { BloodPressure } from '../../../../db/interfaces/BloodPressure'

export interface EditBloodPressureModalProps {
  isOpen: boolean
  onSubmit: (values: any) => void
  onCancel: () => void
  pressure?: BloodPressure
}
export const EditBloodPressureModal = memo<EditBloodPressureModalProps>(
  function EditBloodPressureModal(props) {
    const { isOpen, onSubmit, onCancel, pressure } = props
    return (
      <Modal isOpen={isOpen}>
        <div className="modal-header">
          <div style={{ padding: 0 }} className="modal-title">
            Edit Blood Pressure
          </div>
          <div className="modal-subtitle">Edit blood pressure in this form</div>
        </div>

        <BloodPressureForm
          bloodPressure={pressure}
          onSubmit={onSubmit}
          onCancel={onCancel}
        />
      </Modal>
    )
  },
)

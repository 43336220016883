import React, { memo } from 'react'
import { IconProps } from './IconProps'

export const HeartbeatIcon = memo<IconProps>(function HeartbeatIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="34"
      viewBox="0 0 40 34"
      className={props.className}
    >
      <path
        fillOpacity=".2"
        d="M21 34c8.284 0 15-1.79 15-4s-6.716-4-15-4c-8.284 0-15 1.79-15 4s6.716 4 15 4z"
      />

      <path
        className="fg"
        d="M17.398 4.147l1.416 1.429a.963.963 0 0 0 1.371 0l1.417-1.43A7.186 7.186 0 0 1 26.739 2c1.94 0 3.765.762 5.137 2.147 2.832 2.858 2.832 7.508 0 10.366L19.5 27 7.124 14.513c-2.832-2.858-2.832-7.508 0-10.366A7.186 7.186 0 0 1 12.261 2c1.94 0 3.765.762 5.137 2.147zm3.31 25.56l13.504-13.485A9.432 9.432 0 0 0 37 9.502c0-2.538-.99-4.924-2.788-6.719A9.461 9.461 0 0 0 27.483 0a9.46 9.46 0 0 0-6.73 2.783l-.755.755-.756-.755A9.463 9.463 0 0 0 12.512 0a9.46 9.46 0 0 0-6.729 2.783c-3.71 3.705-3.71 9.733 0 13.438L19.29 29.707a1.003 1.003 0 0 0 1.418 0z"
      />

      <g opacity=".2">
        <path
          fill="#59f9fe"
          d="M19 28a.948.948 0 0 1-.684-.293L6.822 15.827l-1.251-.581a1.014 1.014 0 0 1-.486-1.323.955.955 0 0 1 1.28-.502l1.413.657a.96.96 0 0 1 .287.205L19 25.586l10.935-11.303a.932.932 0 0 1 .141-.12l1.413-.99a.948.948 0 0 1 1.344.266c.3.457.184 1.079-.258 1.389l-1.337.937-11.554 11.942A.948.948 0 0 1 19 28z"
        />
      </g>
      <path
        className="fg"
        d="M4.941 15H1.06C.474 15 0 14.552 0 14s.474-1 1.059-1H4.94C5.526 13 6 13.448 6 14s-.474 1-1.059 1z"
      />
      <path
        className="fg"
        d="M21.165 21a.663.663 0 0 1-.62-.462L16.796 9.525l-1.962 4.423a.658.658 0 0 1-.597.402H5.66c-.365 0-.66-.314-.66-.7 0-.386.295-.7.66-.7h8.158l2.461-5.548a.639.639 0 0 1 .628-.401c.267.013.5.196.59.461l3.662 10.76 3.013-9.006a.665.665 0 0 1 .593-.465.656.656 0 0 1 .63.41l1.473 3.44h9.472c.364 0 .66.313.66.7 0 .385-.296.7-.66.7h-9.897a.658.658 0 0 1-.6-.411l-.974-2.272-3.082 9.216a.663.663 0 0 1-.62.466h-.002z"
      />

      <path className="fg" d="M39 15h-3a1 1 0 1 1 0-2h3a1 1 0 1 1 0 2z" />
    </svg>
  )
})

import React, { memo } from 'react'
import Modal from 'react-modal'
import { ContactForm } from './ContactForm'
import { Contact } from '../../../db/interfaces/Contact'

export interface EditContactModalProps {
  isOpen: boolean
  onSubmit: (values: any) => void
  onCancel: () => void
  contact?: Contact
}

export const EditContactModal = memo<EditContactModalProps>(
  function EditContactModal(props) {
    const { isOpen, onSubmit, onCancel, contact } = props
    return (
      <Modal isOpen={isOpen}>
        <div className="modal-header">
          <div style={{ padding: 0 }} className="modal-title">
            Edit Contact
          </div>
          <div className="modal-subtitle">Edit contact in this form</div>
        </div>

        <ContactForm
          contact={contact}
          onSubmit={onSubmit}
          onCancel={onCancel}
        />
      </Modal>
    )
  },
)

import React, { memo } from 'react'
import Modal from 'react-modal'
import { AllergyForm } from './AllergyForm'

export interface NewAllergyModalProps {
  isOpen: boolean
  onSubmit: (values: any) => void
  onCancel: () => void
}
export const NewAllergyModal = memo<NewAllergyModalProps>(
  function NewAllergyModal(props) {
    const { isOpen, onSubmit, onCancel } = props
    return (
      <Modal isOpen={isOpen}>
        <div className="modal-header">
          <div style={{ padding: 0 }} className="modal-title">
            New Allergy
          </div>
          <div className="modal-subtitle">Enter a new allergy in this form</div>
        </div>

        <AllergyForm onSubmit={onSubmit} onCancel={onCancel} />
      </Modal>
    )
  },
)

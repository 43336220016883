import React, { memo } from 'react'
import Modal from 'react-modal'
import { BodyMassIndexForm } from './BodyMassIndexForm'

export interface NewBodyMassIndexModalProps {
  isOpen: boolean
  onSubmit: (values: any) => void
  onCancel: () => void
}
export const NewBodyMassIndexModal = memo<NewBodyMassIndexModalProps>(
  function NewBodyMassIndexModal(props) {
    const { isOpen, onSubmit, onCancel } = props
    return (
      <Modal isOpen={isOpen}>
        <div className="modal-header">
          <div style={{ padding: 0 }} className="modal-title">
            New BMI
          </div>
          <div className="modal-subtitle">Enter a new bmi in this form</div>
        </div>

        <BodyMassIndexForm onSubmit={onSubmit} onCancel={onCancel} />
      </Modal>
    )
  },
)

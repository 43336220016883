import React, { memo } from 'react'
import { useForm } from 'react-hook-form'

import { HeartRate } from '../../../../db/interfaces/HeartRate'
import { TextInput } from '../../../../components/form/TextInput'

export interface HeartRateFormProps {
  onSubmit: (values?: any) => void
  onCancel: () => void
  heartRate?: HeartRate
}

export const HeartRateForm = memo<HeartRateFormProps>(function HeartRateForm(
  props,
) {
  const { onSubmit, onCancel, heartRate } = props
  const { register, handleSubmit } = useForm<HeartRate>({
    defaultValues: heartRate,
  })
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="form">
        <TextInput inputRef={register} name="date" type="date" label="Date" />
        <TextInput inputRef={register} name="time" type="time" label="Time" />

        <TextInput
          inputRef={register}
          type="number"
          name="bpm"
          label="Beats per Minute"
        />
        {heartRate ? <input ref={register} name="id" type="hidden" /> : null}
        <div className="button-container">
          <div className="form-control">
            <button type="submit" className="btn btn-primary-2 w-full">
              {heartRate ? 'Edit' : 'Add'}
            </button>
          </div>
          <div className="form-control">
            <button
              onClick={e => {
                e.preventDefault()
                onCancel()
              }}
              className="btn w-full"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </form>
  )
})

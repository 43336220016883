import React, { memo } from 'react'
import { useForm } from 'react-hook-form'

import { BloodGlucose } from '../../../../db/interfaces/BloodGlucose'
import { TextInput } from '../../../../components/form/TextInput'

export interface BloodGlucoseFormProps {
  onSubmit: (values?: any) => void
  onCancel: () => void
  bloodGlucose?: BloodGlucose
}

export const BloodGlucoseForm = memo<BloodGlucoseFormProps>(
  function BloodGlucoseForm(props) {
    const { onSubmit, onCancel, bloodGlucose } = props
    const { register, handleSubmit } = useForm<BloodGlucose>({
      defaultValues: bloodGlucose,
    })
    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form">
          <TextInput inputRef={register} name="date" type="date" label="Date" />
          <TextInput inputRef={register} name="time" type="time" label="Time" />

          <TextInput
            inputRef={register}
            type="number"
            name="glucose"
            label="Blood glucose"
          />

          {bloodGlucose ? (
            <input ref={register} name="id" type="hidden" />
          ) : null}

          <div className="button-container">
            <div className="form-control">
              <button type="submit" className="btn btn-primary-2 w-full">
                {bloodGlucose ? 'Edit' : 'Add'}
              </button>
            </div>
            <div className="form-control">
              <button
                onClick={e => {
                  e.preventDefault()
                  onCancel()
                }}
                className="btn w-full"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </form>
    )
  },
)

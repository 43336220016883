import React, { memo } from 'react'
import { useForm } from 'react-hook-form'

import { Allergy } from '../../../db/interfaces/Allergy'
import { TextInput } from '../../../components/form/TextInput'
import NativeSelect, {
  NativeSelectOptions,
} from '../../../components/form/NativeSelect'

export interface AllergyFormProps {
  allergy?: Allergy
  onSubmit: (values?: any) => void
  onCancel: () => void
}
const allergies: NativeSelectOptions[] = [
  {
    label: '',
    value: '',
  },
  {
    label: 'Severe/Anaphylaxis',
    value: 'Severe/Anaphylaxis',
  },
  {
    label: 'Respiratory/Pet',
    value: 'Respiratory/Pet',
  },
  {
    label: 'Food Allergy',
    value: 'Food',
  },
  {
    label: 'Skin Allergy',
    value: 'Skin',
  },
  {
    label: 'Rubber Latex',
    value: 'Latex',
  },
  {
    label: 'Drug Allergy',
    value: 'Drug',
  },
  {
    label: 'Insect Stings',
    value: 'Insect',
  },
  {
    label: 'Other',
    value: 'Other',
  },
]

const yearOptions: NativeSelectOptions[] = [
  {
    label: '2020',
    value: '2020',
  },
  {
    label: '2019',
    value: '2019',
  },
  {
    label: '2018',
    value: '2018',
  },
  {
    label: '2017',
    value: '2017',
  },
  {
    label: '2016',
    value: '2016',
  },
  {
    label: '2015 and older',
    value: '2015',
  },
]

export const AllergyForm = memo<AllergyFormProps>(function AllergyForm(props) {
  const { onSubmit, onCancel, allergy } = props
  const { register, handleSubmit } = useForm<Allergy>({
    defaultValues: allergy,
  })
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="form">
        <NativeSelect
          selectRef={register}
          name="allergy"
          label="Select allergy"
          options={allergies}
          defaultValue={''}
        />
        <NativeSelect
          selectRef={register}
          name="year"
          label="Select year"
          options={yearOptions}
          defaultValue={''}
        />
        <TextInput inputRef={register} name="description" label="Description" />

        {allergy ? <input ref={register} name="id" type="hidden" /> : null}

        <div className="button-container">
          <div className="form-control">
            <button type="submit" className="btn btn-primary-2 w-full">
              {allergy ? 'Edit' : 'Add'}
            </button>
          </div>
          <div className="form-control">
            <button
              onClick={e => {
                e.preventDefault()
                onCancel()
              }}
              className="btn w-full"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </form>
  )
})

import React, { memo } from 'react'
import { IconProps } from './IconProps'

export const BackIcon = memo<IconProps>(props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="26"
    height="26"
    viewBox="0 0 26 26"
    className="back-icon"
  >
    <path
      className="bg"
      d="M13 26h-.002a12.924 12.924 0 0 1-9.197-3.807c-5.068-5.07-5.068-13.317.002-18.387A12.918 12.918 0 0 1 12.996 0c3.474 0 6.74 1.352 9.194 3.806A12.913 12.913 0 0 1 26 13a12.91 12.91 0 0 1-3.808 9.194A12.912 12.912 0 0 1 13 26m.001-23.636a10.577 10.577 0 0 0-7.525 3.115c-4.15 4.15-4.15 10.901-.002 15.05a10.584 10.584 0 0 0 7.53 3.117h.002c2.842 0 5.513-1.106 7.522-3.115a10.572 10.572 0 0 0 3.117-7.526c0-2.843-1.107-5.516-3.119-7.526a10.577 10.577 0 0 0-7.525-3.115"
    />
    <path
      className="bg"
      d="M15.504 18.91a.998.998 0 0 1-.732-.326l-5.018-5.387a1.16 1.16 0 0 1-.304-.787c0-.295.11-.579.304-.787l5.018-5.387a.986.986 0 0 1 1.465 0 1.17 1.17 0 0 1 0 1.574l-4.285 4.6 4.285 4.6a1.17 1.17 0 0 1 0 1.574.998.998 0 0 1-.733.326"
    />
  </svg>
))

import React, { memo, useState } from 'react'

import { useAction } from '../../../../util/useAction'
import { ActionTypes } from '../../../../state/actions'
import { useDbSelector } from '../../../../util/useDbSelector'
import { BodyMassIndexCollection } from '../../../../db/bodyMassIndex'
import { BodyMassIndex } from '../../../../db/interfaces/BodyMassIndex'
import { BodyMassIndexList } from './BodyMassIndexList'
import { ListToggle } from '../../ListToggle'
import { NewBodyMassIndexModal } from './NewBodyMassIndexModal'
import { EditBodyMassIndexModal } from './EditBodyMassIndexModal'

export const BodyMassIndexContent = memo(function BodyMassIndexContent() {
  const [showModalNew, setShowModalNew] = useState(false)
  const hideModalNew = () => setShowModalNew(false)

  const [showModalEdit, setShowModalEdit] = useState(false)
  const hideModalEdit = () => setShowModalEdit(false)

  const [bmiId, setBmiId] = useState(-1)
  const saveBmi = useAction(ActionTypes.BODY_MASS_INDEX_SAVE)

  const bmiRecords = useDbSelector<BodyMassIndex>(BodyMassIndexCollection)

  const bmiRecord = bmiRecords.find(r => r.id === bmiId)

  const onEdit = (id: number) => {
    setBmiId(id)
    setShowModalEdit(true)
  }

  const onSubmitNew = (rate: Partial<BodyMassIndex>) => {
    saveBmi(rate)
    hideModalNew()
  }

  const onSubmitEdit = (rate: Partial<BodyMassIndex>) => {
    saveBmi(rate)
    hideModalEdit()
  }

  const deleteRecord = (id: number | string) =>
    BodyMassIndexCollection.delete(id)

  return (
    <div>
      <div className="health-stats-top-bar">
        <div className="stat-description">Body Mass Index</div>
        <button onClick={() => setShowModalNew(true)} className="btn">
          Add
        </button>
      </div>

      <NewBodyMassIndexModal
        isOpen={showModalNew}
        onCancel={hideModalNew}
        onSubmit={onSubmitNew}
      />
      <EditBodyMassIndexModal
        isOpen={showModalEdit}
        onCancel={hideModalEdit}
        onSubmit={onSubmitEdit}
        bmi={bmiRecord}
      />
      <ListToggle recordsCount={bmiRecords.length}>
        <BodyMassIndexList
          onDelete={deleteRecord}
          onEdit={onEdit}
          bmiList={bmiRecords}
        />
      </ListToggle>
    </div>
  )
})

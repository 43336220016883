import React, { memo } from 'react'
import cx from 'clsx'
import { IconProps } from './IconProps'

export const ClockIcon = memo<IconProps>(props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 35 36"
    className={cx('clock-icon', props.className)}
  >
    {!props.noShadow ? (
      <path
        fillOpacity=".2"
        d="M17.43 36.003c9.356 0 16.94-1.848 16.94-4.127s-7.584-4.126-16.94-4.126C8.074 27.75.49 29.597.49 31.876c0 2.28 7.584 4.127 16.94 4.127z"
      />
    ) : null}

    <path
      className="fg"
      d="M17.947 32.003c8.807 0 15.947-7.14 15.947-15.946C33.894 7.249 26.754.11 17.947.11 9.14.11 2 7.25 2 16.057c0 8.807 7.14 15.946 15.947 15.946"
    />

    <path
      className="bg"
      d="M17.996 29.173c7.278 0 13.177-5.9 13.177-13.176 0-7.278-5.9-13.177-13.177-13.177-7.277 0-13.175 5.9-13.176 13.177 0 7.277 5.9 13.176 13.176 13.176"
    />

    <path className="fg" d="M17.06 7.534h.941V4.71h-.941z" />

    <path className="fg" d="M22.645 8.474l-.885-.645.997-2.179.885.644z" />

    <path className="fg" d="M26.174 11.292l-.644-.885 2.18-.997.644.885z" />

    <path className="fg" d="M27.41 16.941h2.823V16H27.41z" />

    <path className="fg" d="M27.71 22.592l-2.18-.998.644-.884 2.18.997z" />

    <path className="fg" d="M22.757 27.294l-.997-2.18.885-.644.997 2.18z" />

    <path className="fg" d="M18 28.233h-.94V25.41h.941z" />

    <path className="fg" d="M12.294 27.294l-.884-.645.998-2.179.884.645z" />

    <path className="fg" d="M8.294 22.592l-.644-.885 2.18-.997.644.885z" />

    <path className="fg" d="M5.76 16.941h2.823V16H5.76z" />

    <path className="fg" d="M9.83 11.292l-2.18-.997.644-.885 2.18.997z" />

    <path className="fg" d="M12.407 8.474l-.997-2.18.885-.644.997 2.18z" />

    <path
      className="fg"
      d="M17.532 17.884c-.348 0-.681-.128-.94-.36a1.405 1.405 0 0 1-.47-.973 1.415 1.415 0 0 1 2.351-1.131c.58.518.63 1.412.112 1.993-.268.3-.652.47-1.053.47m0-1.525a.113.113 0 0 0-.114.12.112.112 0 0 0 .039.079c.039.035.098.061.16-.01a.114.114 0 0 0-.085-.19"
    />

    <path className="fg" d="M19.016 15.998L18 15.161l5.573-5.751 1.015.838z" />

    <path
      className="fg"
      d="M17.196 16.937l-4.846-4.679.801-.968 4.846 4.68v-.001z"
    />
  </svg>
))

import React, { memo } from 'react'
import cx from 'clsx'
import { IconProps } from './IconProps'

export const ExpandedIcon = memo<IconProps>(props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    className={cx('expanded-icon', props.className)}
  >
    <path
      fill="#fff"
      d="M15.705 12.78H7.83a1 1 0 1 1 0-2h7.875a1 1 0 1 1 0 2"
    />
    <path
      fill="#fff"
      d="M11.769 23.552a11.699 11.699 0 0 1-8.326-3.448c-4.592-4.592-4.59-12.064.002-16.656A11.701 11.701 0 0 1 11.771 0c3.146 0 6.104 1.225 8.328 3.448 4.592 4.593 4.592 12.064 0 16.655a11.704 11.704 0 0 1-8.33 3.449M11.771 2a9.715 9.715 0 0 0-6.912 2.862C1.046 8.674 1.046 14.877 4.857 18.69a9.71 9.71 0 0 0 6.912 2.862 9.718 9.718 0 0 0 6.916-2.864c3.811-3.811 3.811-10.013 0-13.826A9.716 9.716 0 0 0 11.771 2"
    />
  </svg>
))

import { parsePhoneNumberFromString } from 'libphonenumber-js/max'

export const isValidPhoneNumber = (phone: string) => {
  if (!phone) {
    return true
  }
  const phoneNumber = parsePhoneNumberFromString(phone, 'GB')
  if (phoneNumber) {
    return phoneNumber.isValid()
  }
  return false
}

export const isValidPhoneNumberStrict = (phone: string) => {
  if (!phone) {
    return false
  }
  const phoneNumber = parsePhoneNumberFromString(phone, 'GB')
  if (phoneNumber) {
    return phoneNumber.isValid()
  }
  return false
}

import React, { memo } from 'react'
import { useForm } from 'react-hook-form'

import { BodyMassIndex } from '../../../../db/interfaces/BodyMassIndex'
import { TextInput } from '../../../../components/form/TextInput'

export interface BodyMassIndexFormProps {
  onSubmit: (values?: any) => void
  onCancel: () => void
  massIndex?: BodyMassIndex
}

export const BodyMassIndexForm = memo<BodyMassIndexFormProps>(
  function BodyMassIndexForm(props) {
    const { onSubmit, onCancel, massIndex } = props
    const { register, handleSubmit } = useForm<BodyMassIndex>({
      defaultValues: massIndex,
    })
    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form">
          <TextInput inputRef={register} name="date" type="date" label="Date" />
          <TextInput inputRef={register} name="time" type="time" label="Time" />

          <TextInput inputRef={register} type="number" name="bmi" label="BMI" />

          {massIndex ? <input ref={register} name="id" type="hidden" /> : null}
          <div className="button-container">
            <div className="form-control">
              <button type="submit" className="btn btn-primary-2 w-full">
                {massIndex ? 'Edit' : 'Add'}
              </button>
            </div>
            <div className="form-control">
              <button
                onClick={e => {
                  e.preventDefault()
                  onCancel()
                }}
                className="btn w-full"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </form>
    )
  },
)

import React, { memo } from 'react'
import { IconProps } from './IconProps'

export const StethoscopeIcon = memo<IconProps>(function SthetoscopeIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 44 39"
      className={props.className}
    >
      <path
        className="fg"
        d="M7.998 26L.842 17.979c-.824-.924-.899-1.797-.816-2.367.222-1.533 1.75-2.43 1.923-2.528.35-.196.776-.038.951.355.175.392.033.87-.317 1.066-.27.155-1.07.739-1.158 1.364-.016.11-.064.446.419.987L9 24.876z"
      />

      <path
        className="fg"
        d="M17.878 17L9.856 9.844c-.542-.483-.878-.435-.99-.42-.643.094-1.237.938-1.36 1.16-.197.35-.674.491-1.067.317-.392-.175-.551-.601-.355-.951.097-.174.994-1.702 2.528-1.924.57-.083 1.443-.007 2.367.816L19 15.998z"
      />

      <path
        className="shadow"
        fillOpacity="0.2"
        d="M25.5 39c9.665 0 17.5-2.015 17.5-4.5S35.165 30 25.5 30 8 32.015 8 34.5 15.835 39 25.5 39z"
      />

      <path
        className="fg"
        d="M27.274 32c-1.959 0-3.942-.775-5.46-2.316l-3.5-3.55a1.1 1.1 0 0 1 0-1.54c.42-.426 1.1-.426 1.52 0l3.499 3.55c2.129 2.159 5.508 2.246 7.532.192.988-1.001 1.509-2.348 1.468-3.79a5.709 5.709 0 0 0-1.656-3.854l-7.698-7.81a7.358 7.358 0 0 1-2.126-5.352 7.758 7.758 0 0 1 2.255-5.31 7.411 7.411 0 0 1 10.578 0l7 7.101a1.1 1.1 0 0 1 0 1.54c-.42.427-1.1.427-1.52 0l-7-7.1a5.284 5.284 0 0 0-7.539 0 5.575 5.575 0 0 0-1.626 3.812 5.181 5.181 0 0 0 1.497 3.769l7.697 7.81a7.893 7.893 0 0 1 2.284 5.331c.059 2.05-.686 3.965-2.095 5.395A7.13 7.13 0 0 1 27.273 32z"
      />

      <path
        className="fg"
        d="M14.128 28h-.104c-2.168-.025-4.188-.808-5.69-2.205a1.009 1.009 0 0 1 0-1.5 1.201 1.201 0 0 1 1.613 0c1.062.989 2.558 1.566 4.104 1.583 1.56.017 3.017-.516 4.077-1.502a4.935 4.935 0 0 0 1.59-3.762c-.03-1.424-.659-2.81-1.726-3.803a1.009 1.009 0 0 1 0-1.5 1.201 1.201 0 0 1 1.612 0c1.503 1.397 2.353 3.266 2.394 5.262.042 2.027-.76 3.91-2.257 5.303C18.266 27.248 16.277 28 14.128 28z"
      />

      <path className="fg" d="M40 15a4 4 0 1 0 0-8 4 4 0 0 0 0 8z" />

      <path className="bg" d="M40.5 14a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z" />

      <g transform="rotate(-135 2.5 14.5)">
        <path className="fg" d="M2.5 16a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
      </g>
      <g transform="rotate(-135 7.5 10.5)">
        <path className="fg" d="M7.5 12a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
      </g>
    </svg>
  )
})

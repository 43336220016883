import React, { memo } from 'react'
import { Consultation } from '../../../db/interfaces/Consultation'
import { ConfirmButton } from '../../../components/ConfirmButton'

export interface ConsultationsListProps {
  consultations: Consultation[]
  onDelete: (id: string | number) => void | Promise<void>
  onEdit: (id: number) => void
}
export const ConsultationsList = memo<ConsultationsListProps>(
  function ConsultationsList(props) {
    const { consultations, onDelete, onEdit } = props
    return (
      <div className="consultations-list list">
        {consultations.map((consultation, i) => (
          <div key={`consultation-${i}`} className="box">
            <div className="box-row box-row-header">
              <div className="category box-cell">
                {consultation.mainCategory}
              </div>
              <div className="date box-cell">
                {new Date(
                  Date.parse(consultation.consultationDate),
                ).toLocaleString()}
              </div>
            </div>

            <div className="box-row">
              <div className="title box-cell"> Outcome: </div>
              <div className="value box-cell">{consultation.outcome}</div>
            </div>

            <div className="box-row">
              <div className="box-cell title"> Clinical Categories: </div>
              <div className="box-cell value">
                {[
                  consultation.clinicalCategory1,
                  consultation.clinicalCategory2,
                  consultation.clinicalCategory3,
                ].join(',')}
              </div>
            </div>

            <div className="box-row actions">
              <div className="box-cell">
                <button
                  onClick={() => onEdit(consultation.id)}
                  className="btn flat btn-primary-2-inversed w-full action-edit"
                >
                  Edit
                </button>
              </div>
              <div className="box-cell">
                <ConfirmButton
                  onConfirm={() => onDelete(consultation.id)}
                  className="btn flat btn-primary-2-inversed w-full action-delete"
                >
                  Delete
                </ConfirmButton>
              </div>
            </div>
          </div>
        ))}
      </div>
    )
  },
)

import { Action } from '../types/Action'
import { ActionCreator, createAction } from './createAction'
import { useDispatch } from 'react-redux'
import { useMemo } from 'react'

export function useAction<
  A extends Action<T, P>,
  T = A['type'],
  P = A['payload']
>(type: T): ActionCreator<A>
export function useAction<
  A extends Action<T, P>,
  T = A['type'],
  P = A['payload']
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
>(type: T, includeMeta: true): (payload: P) => Promise<any>

export function useAction<
  A extends Action<T, P, M>,
  T = A['type'],
  P = A['payload'],
  M = A['meta']
>(type: T, includeMeta = false) {
  const dispatch = useDispatch()
  return useMemo(() => {
    const action = createAction<A>(type)
    return (payload: P) =>
      includeMeta
        ? new Promise((resolve, reject) =>
            dispatch(action(payload, { resolve, reject })),
          )
        : dispatch(action(payload))
  }, [dispatch, type, includeMeta])
}

import React, { memo } from 'react'
import { BloodGlucoseContent } from './HealthStatistics/BloodGlucose/BloodGlucoseContent'
import { BloodPressureContent } from './HealthStatistics/BloodPressure/BloodPressureContent'
import { HeartRateContent } from './HealthStatistics/HeartRate/HeartRateContent'
import { BodyMassIndexContent } from './HealthStatistics/BodyMassIndex/BodyMassIndexContent'

export const HealthStatistics = memo(function HealthStatistics() {
  return (
    <>
      <BloodGlucoseContent />
      <BloodPressureContent />
      <HeartRateContent />
      <BodyMassIndexContent />
    </>
  )
})

import React, { memo, useEffect } from 'react'
import { Link } from 'react-router-dom'

import { StethoscopeIcon } from '../../components/icons/StethoscopeIcon'
import { HeartbeatIcon } from '../../components/icons/HeartbeatIcon'
import { MedicalHistoryIcon } from '../../components/icons/MedicalHistoryIcon'
import { ScreenRoutes } from '../../types/ScreenRoutes'
import { PhoneConsultationIcon } from '../../components/icons/PhoneConsultationIcon'

import heroImg from '../../assets/images/home-hero-mobile.png'
import logoImg from '../../assets/images/HMCA-members-logo.jpg'
import logoImgPng from '../../assets/images/HMCA-members-logo.png'
import heroImgDesktop from '../../assets/images/home-hero-desktop.jpg'
import medsolLogoImg from '../../assets/images/HealthHeroLogo.svg'

export const HomePage = memo(function HomePage() {
  useEffect(() => {
    window.gtag('config', 'UA-78156948-64')
  }, [])
  return (
    <div className="pattern-bg page home-page">
      <div className="header">
        <img width="80" height="80" src={logoImg} alt="logo" />
      </div>
      <div className="hero-unit">
        <img
          className="hero-banner-mobile hide-tablet"
          src={heroImg}
          alt="banner"
        />
        <img
          className="hero-banner-desktop"
          src={heroImgDesktop}
          alt="banner"
        />

        <img src={logoImgPng} className="hero-logo" alt="logo" />
        <div className="hero-text">GP Advice Line</div>
        <div className="hero-description hide-mobile">
          With 24/7 access to our private helpline, you can <br /> speak to a GP
          anytime you need, wherever you are
        </div>
      </div>
      <div className="bottom-container">
        <div className="button-tile-container">
          <div className="button-tile button-tile-primary">
            <div className="button-container">
              <StethoscopeIcon className="thethoscope-icon button-icon" />
              <Link to={ScreenRoutes.GP_CONSULT}>
                <button className="btn">Speak to a GP</button>
              </Link>
            </div>
          </div>
          <div className="button-tile button-tile-secondary">
            <div className="button-container">
              <HeartbeatIcon className="button-icon heart-icon" />
              <a
                href="https://medicalsolutions.typeform.com/to/GQU4Bc"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button className="btn">Feedback</button>
              </a>
            </div>
          </div>
          <div className="button-tile button-tile-secondary">
            <div className="button-container">
              <MedicalHistoryIcon className="medical-history-icon button-icon" />
              <Link to={ScreenRoutes.HEALTH_INFORMATION}>
                <button className="btn">Health Info</button>
              </Link>
            </div>
          </div>
        </div>
        <div className="appointment-info">
          <div className="consultation-info">
            <div className="consultation-info-icon-container display-flex flex-center">
              <PhoneConsultationIcon />
            </div>
            <div className="consultation-info-description">
              <div className="consultation-text">
                To book an appointment with a GP 24/7 call:
              </div>
              <div className="consultation-phone">01423 798 198</div>
              <Link to={ScreenRoutes.REQUEST_APPOINTMENT}>
                or use our online booking form >
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="footer display-flex flex-center">
          <img
            className="medsol-logo"
            src={medsolLogoImg}
            alt="HealthHero Logo"
          />
          <div className="footer-text">
            GP24 is a service provided by HealthHero Solutions Limited,
            specialists in 24/7 Private GP services.
          </div>
        </div>
      </div>
    </div>
  )
})

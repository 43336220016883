import React, { memo } from 'react'

export interface HeaderBgMobileProps {
  className?: string
  pathProps?: React.SVGProps<SVGPathElement>
  dropShadow?: boolean
}

const defaultPathProps: React.SVGProps<SVGPathElement> = {}

const DropShadow = () => (
  <filter id="mobile-header-shadow" height="130%">
    <feGaussianBlur in="SourceAlpha" stdDeviation="3" />
    <feOffset dx="2" dy="2" result="offsetblur" />
    <feComponentTransfer>
      <feFuncA type="linear" slope="0.5" />
    </feComponentTransfer>
    <feMerge>
      <feMergeNode />
      <feMergeNode in="SourceGraphic" />
    </feMerge>
  </filter>
)

export const HeaderBgMobile = memo<HeaderBgMobileProps>(function HeaderBgMobile(
  props,
) {
  const { pathProps = defaultPathProps, dropShadow } = props

  const _pathProps = {
    ...pathProps,
  }
  if (dropShadow) {
    _pathProps.style = Object.assign(pathProps.style || {}, {
      filter: 'url(#mobile-header-shadow)',
    })
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 469 176"
      className={props.className}
      preserveAspectRatio="none"
    >
      {dropShadow ? <DropShadow /> : null}
      <g transform="translate(-30, -20)">
        <path
          {..._pathProps}
          d="M11.437 64.21c-5.706 41.422 209.086 124.84 375 99.776C552.351 138.922 401 3.586 401 3.586S22.83-1.697 11.437 20.214c-15.07 28.983 1.208 35.23 0 43.995z"
          className="bg"
        />
      </g>
    </svg>
  )
})

import React, { memo } from 'react'
import Modal from 'react-modal'
import { ContactForm } from './ContactForm'

export interface NewContactModalProps {
  isOpen: boolean
  onSubmit: (values: any) => void
  onCancel: () => void
}

export const NewContactModal = memo<NewContactModalProps>(
  function NewContactModal(props) {
    const { isOpen, onSubmit, onCancel } = props
    return (
      <Modal isOpen={isOpen}>
        <div className="modal-header">
          <div style={{ padding: 0 }} className="modal-title">
            New Contact
          </div>
          <div className="modal-subtitle">Enter a new contact in this form</div>
        </div>

        <ContactForm onSubmit={onSubmit} onCancel={onCancel} />
      </Modal>
    )
  },
)

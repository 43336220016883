import React, { memo } from 'react'
import Modal from 'react-modal'
import { AllergyForm } from './AllergyForm'
import { Allergy } from '../../../db/interfaces/Allergy'

export interface EditAllergyModalProps {
  isOpen: boolean
  onSubmit: (values: any) => void
  onCancel: () => void
  allergy?: Allergy
}
export const EditAllergyModal = memo<EditAllergyModalProps>(
  function EditAllergyModal(props) {
    const { isOpen, onSubmit, onCancel, allergy } = props
    return (
      <Modal isOpen={isOpen}>
        <div className="modal-header">
          <div style={{ padding: 0 }} className="modal-title">
            Edit Allergy
          </div>
          <div className="modal-subtitle">Edit allergy in this form</div>
        </div>

        <AllergyForm
          allergy={allergy}
          onSubmit={onSubmit}
          onCancel={onCancel}
        />
      </Modal>
    )
  },
)

import React, { memo, useState } from 'react'
import { Medicine } from '../../../db/interfaces/Medicine'
import { MedicineCollection } from '../../../db/medicine'
import { useAction } from '../../../util/useAction'
import { useDbSelector } from '../../../util/useDbSelector'
import { ActionTypes } from '../../../state/actions'
import { MedicineList } from './MedicineList'
import { EditMedicineModal } from './EditMedicineModal'
import { NewMedicineModal } from './NewMedicineModal'

export const MedicinesContent = memo(function MedicinesContent() {
  const [showModalNew, setShowModalNew] = useState(false)
  const hideModalNew = () => setShowModalNew(false)

  const [showModalEdit, setShowModalEdit] = useState(false)
  const hideModalEdit = () => setShowModalEdit(false)

  const [medicineId, setMedicineId] = useState(-1)
  const saveConsultation = useAction(ActionTypes.ALLERGY_SAVE)

  const medicines = useDbSelector<Medicine>(MedicineCollection)

  const allergy = medicines.find(a => a.id === medicineId)

  const onEdit = (id: number) => {
    setMedicineId(id)
    setShowModalEdit(true)
  }

  const onSubmitNew = (consultation: Partial<Medicine>) => {
    saveConsultation(consultation)
    hideModalNew()
  }

  const onSubmitEdit = (consultation: Partial<Medicine>) => {
    saveConsultation(consultation)
    hideModalEdit()
  }

  const deleteRecord = (id: number | string) => MedicineCollection.delete(id)
  return (
    <>
      <div>
        <button onClick={() => setShowModalNew(true)} className="btn">
          Enter Medicine
        </button>
      </div>
      <MedicineList
        onEdit={onEdit}
        onDelete={deleteRecord}
        medicines={medicines}
      />
      <EditMedicineModal
        isOpen={showModalEdit}
        onCancel={hideModalEdit}
        onSubmit={onSubmitEdit}
        medicine={allergy}
      />
      <NewMedicineModal
        isOpen={showModalNew}
        onCancel={hideModalNew}
        onSubmit={onSubmitNew}
      />
    </>
  )
})

import React, { memo } from 'react'
import { BodyMassIndex } from '../../../../db/interfaces/BodyMassIndex'
import { ConfirmButton } from '../../../../components/ConfirmButton'

export interface BMIListProps {
  bmiList: BodyMassIndex[]
  onDelete: (id: string | number) => void | Promise<void>
  onEdit: (id: number) => void
}

export const BodyMassIndexList = memo<BMIListProps>(function BMIList(props) {
  const { bmiList, onDelete, onEdit } = props
  return (
    <div className="bmi-list list">
      {bmiList.map(bmi => (
        <div className="box">
          <div className="box-row">
            <div className="title box-cell">Date: </div>
            <div className="value box-cell">{bmi.date}</div>
          </div>
          <div className="box-row">
            <div className="title box-cell"> Time: </div>
            <div className="value box-cell">{bmi.time}</div>
          </div>
          <div className="box-row">
            <div className="box-cell title"> BMI: </div>
            <div className="box-cell value">{bmi.bmi}</div>
          </div>

          <div className="box-row actions">
            <div className="box-cell">
              <button
                onClick={() => onEdit(bmi.id)}
                className="btn flat btn-primary-2-inversed w-full action-edit"
              >
                Edit
              </button>
            </div>
            <div className="box-cell">
              <ConfirmButton
                onConfirm={() => onDelete(bmi.id)}
                className="btn flat btn-primary-2-inversed w-full action-delete"
              >
                Delete
              </ConfirmButton>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
})

import React, { memo } from 'react'
import Modal from 'react-modal'
import { BloodGlucoseForm } from './BloodGlucoseForm'
import { BloodGlucose } from '../../../../db/interfaces/BloodGlucose'

export interface EditBloodGlucoseModalProps {
  isOpen: boolean
  onSubmit: (values: any) => void
  onCancel: () => void
  glucose?: BloodGlucose
}
export const EditBloodGlucoseModal = memo<EditBloodGlucoseModalProps>(
  function EditBloodGlucoseModal(props) {
    const { isOpen, onSubmit, onCancel, glucose } = props
    return (
      <Modal isOpen={isOpen}>
        <div className="modal-header">
          <div style={{ padding: 0 }} className="modal-title">
            Edit Blood Glucose
          </div>
          <div className="modal-subtitle">Edit blood glucose in this form</div>
        </div>

        <BloodGlucoseForm
          bloodGlucose={glucose}
          onSubmit={onSubmit}
          onCancel={onCancel}
        />
      </Modal>
    )
  },
)

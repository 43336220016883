import React, { memo } from 'react'

import { EConsultationIcon } from '../../components/icons/EConsultationIcon'
import { PhoneConsultationIcon } from '../../components/icons/PhoneConsultationIcon'
import { PageHeader } from '../../components/app/PageHeader'

export const EConsultationsPage = memo(function EConsultationsPage() {
  return (
    <div className="page e-consultations-page pattern-bg">
      <PageHeader title="eConsultations" icon={<EConsultationIcon />} />
      <div className="section-header">
        Book a video consultation with an experienced doctor
      </div>

      <div className="content table-container">
        <table className="appointment-table">
          <tr>
            <td className="text-left">Monday - Sunday</td>
            <td className="text-right">08:00-22:00</td>
          </tr>
        </table>
        <div className="content">
          <div className="footnote">Excluding UK bank holidays</div>
        </div>
      </div>
      <div className="section content">
        <div className="button-tile button-tile-primary">
          <a href="tel:01423 798198">
            <div className="button-container">
              <PhoneConsultationIcon className="button-icon" />
              <button className="btn mt-1 ">
                Call to book an eConsultation
              </button>
            </div>
          </a>
        </div>

        <div className="section-header">After you’ve booked an appointment</div>
        <div className="content e-consultation-content">
          <div className="paragraph content e-consultation-content">
            Once you’ve booked an appointment an email containing a link to join
            the eConsultation will be sent to you.
          </div>
        </div>
      </div>
    </div>
  )
})

import React, { memo, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useForm, NestDataObject, FieldError } from 'react-hook-form'

import { PageHeader } from '../../components/app/PageHeader'
import { ClockIcon } from '../../components/icons/ClockIcon'
import { TextInput } from '../../components/form/TextInput'
import { ScreenRoutes } from '../../types/ScreenRoutes'
import { requestAppointment } from '../../api'
import { isValidEmailStrict } from '../../util/validation/email'
import { isValidPhoneNumberStrict } from '../../util/validation/phone'
import { loadReCaptcha } from '../../util/recaptcha'
import config from '../../config'
import { TextArea } from '../../components/form/TextArea'

const errorsMap: Record<string, string> = {
  phone: 'Enter valid phone number',
  email: 'Enter valid email address',
  postcode: 'Enter valid postcode',
  required: 'Required',
}

function getError<T>(errors: NestDataObject<T>, field: keyof T) {
  const err = errors[field] as FieldError
  if (!err) {
    return null
  }
  if (err.message) {
    return err.message
  }

  if (errorsMap[err.type]) {
    return errorsMap[err.type]
  }
  return null
}

export const RequestAppointmentPage = memo(function RequestAppointmentPage() {
  const freeTextDescriptionLabel = `
    Please write a short description about the reason for your appointment.
    Please include any relevant medication you might be taking.
    `

  const { register, handleSubmit, errors } = useForm()

  const [submitErrorMesasge, setSubmitErrorMessage] = useState('')

  const [img, setImg] = useState<any>(null)

  const history = useHistory()

  useEffect(() => loadReCaptcha(config.recaptcha.siteKey), [])

  const onSubmit = async (message: any) => {
    setSubmitErrorMessage('')
    // @ts-ignore
    const recaptchaToken = await grecaptcha.execute(config.recaptcha.siteKey, {
      action: 'request_appointment',
    })
    const response = await requestAppointment({
      ...message,
      recaptchaToken,
      appId: config.appId,
    })
    if (response.success) {
      history.push(ScreenRoutes.REQUEST_CONFIRMED)
      return
    }
    setSubmitErrorMessage(response.message)
  }

  const checkKeyDown = (
    e: React.KeyboardEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLFormElement
    >,
  ) => {
    const keyCode = e.keyCode ? e.keyCode : e.which

    //@ts-ignore
    if (keyCode === 13 && e.target.tagName?.toLowerCase() !== 'textarea') {
      e.preventDefault()
    }
  }

  return (
    <div className="page request-appointment-page">
      <PageHeader title="Request an Appointment" icon={<ClockIcon />} />
      <div className="content paragraph">
        We will send you an email with the next available appointment, within 4
        hours of your request.
      </div>
      <div className="content table-container">
        <table className="appointment-table">
          <tbody>
            <tr>
              <td className="text-left">Monday - Sunday</td>
              <td className="text-right">08:00-22:00</td>
            </tr>
          </tbody>
        </table>
        <div className="content">
          <div className="footnote">Excluding UK bank holidays</div>
        </div>
      </div>
      <form
        onKeyDown={checkKeyDown}
        onSubmit={handleSubmit(onSubmit)}
        className="form"
      >
        <TextInput
          inputRef={register}
          name="policyNumber"
          label="Policy Number/Access Code"
        />
        <TextInput
          error={getError(errors, 'fullName')}
          inputRef={register({ required: true })}
          name="fullName"
          label="Full Name"
        />
        <TextInput
          error={getError(errors, 'dob')}
          inputRef={register({ required: true })}
          name="dob"
          type="date"
          label="D.O.B"
        />

        <div className="form-control">
          <label>Gender</label>
          <div className="radio-group">
            <label className="radio-button">
              <input
                ref={register({ required: true })}
                type="radio"
                name="gender"
                value="Male"
              />
              <span className="radio-button-control"></span>
              <span className="radio-button-label">Male</span>
            </label>
            <label className="radio-button">
              <input
                ref={register({ required: true })}
                type="radio"
                className="radio-button-input"
                name="gender"
                value="Female"
              />
              <span className="radio-button-control"></span>
              <span className="radio-button-label">Female</span>
            </label>
          </div>
          {errors.gender ? (
            <div className="error-message">{getError(errors, 'gender')}</div>
          ) : null}
        </div>
        <TextInput
          error={getError(errors, 'email')}
          inputRef={register({
            validate: {
              email: isValidEmailStrict,
            },
          })}
          name="email"
          label="Email"
        />
        <TextInput
          error={getError(errors, 'phone')}
          inputRef={register({
            validate: {
              phone: isValidPhoneNumberStrict,
            },
          })}
          name="phone"
          label="Phone Number"
        />
        <TextInput
          error={getError(errors, 'addressLine1')}
          inputRef={register({ required: true })}
          name="addressLine1"
          label="First Line of Address"
        />
        <TextInput
          inputRef={register}
          name="addressLine2"
          label="Second Line of Address"
        />
        <TextInput
          error={getError(errors, 'city')}
          inputRef={register({ required: true })}
          name="city"
          label="Town or City"
        />
        <TextInput inputRef={register} name="postcode" label="Postcode" />
        <div className="form-control">
          <label>Consultation Type</label>
          <div className="radio-group">
            <label className="radio-button">
              <input
                ref={register({ required: true })}
                type="radio"
                name="consultationType"
                value="Telephone"
              />
              <span className="radio-button-control"></span>
              <span className="radio-button-label">Telephone</span>
            </label>
            <label className="radio-button">
              <input
                ref={register({ required: true })}
                type="radio"
                className="radio-button-input"
                name="consultationType"
                value="Video"
              />
              <span className="radio-button-control"></span>
              <span className="radio-button-label">Video</span>
            </label>
          </div>
          {errors.consultationType ? (
            <div className="error-message">
              {getError(errors, 'consultationType')}
            </div>
          ) : null}
        </div>

        <TextArea
          rows={5}
          error={getError(errors, 'description')}
          inputRef={register({ required: true })}
          name="description"
          label={freeTextDescriptionLabel}
        />

        <div className="form-control">
          <label className="btn input-file-label">
            <span>Attach an image</span>
            <input
              name="image"
              ref={register}
              type="file"
              onChange={e =>
                setImg(
                  e.target.files && e.target.files[0]
                    ? URL.createObjectURL(e.target.files[0])
                    : null,
                )
              }
              accept="image/*"
              className="input-file"
            />
          </label>
          <img alt="" style={{ height: '60px', marginTop: '10px' }} src={img} />
        </div>
        <div className="form-control">
          <div className="recaptcha-policy content paragraph">
            In addition to HealthHero{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.healthhero.com/privacy-policy/"
            >
              Privacy Policy
            </a>{' '}
            and{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.healthhero.com/terms-and-conditions/"
            >
              Terms and Conditions
            </a>{' '}
            , this site is protected by reCAPTCHA and the Google{' '}
            <a href="https://policies.google.com/privacy">Privacy Policy</a> and{' '}
            <a href="https://policies.google.com/terms">Terms of Service</a>{' '}
            apply.
          </div>
          <button type="submit" className="btn btn-request-consultation">
            Send
          </button>
        </div>
      </form>
      {!!submitErrorMesasge ? (
        <div className="error-message">{submitErrorMesasge}</div>
      ) : null}
    </div>
  )
})

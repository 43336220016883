/* eslint-disable @typescript-eslint/no-explicit-any */
import Dexie from 'dexie'
import { useState, useEffect, useCallback } from 'react'
import { subscribe } from '../db'

export function useDbSelector<T>(
  collection: Dexie.Table<T, any>,
  queryBuilder?: (collection: Dexie.Table<T, any>) => Dexie.Collection<T, any>,
) {
  const [state, change] = useState<T[]>([])
  const updater = useCallback(
    async (changes: any[] = []) => {
      const data = await (queryBuilder
        ? queryBuilder(collection).toArray()
        : collection.toArray())

      if (
        state.length !== data.length ||
        (data.length === 0 && changes.length !== 0) ||
        (data.length > 0 && data.some((item, index) => item !== state[index]))
      ) {
        change([...data])
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [collection, queryBuilder],
  )
  useEffect(() => {
    updater()
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    return subscribe(collection.name, updater)
  }, [collection, updater, queryBuilder])
  return state
}

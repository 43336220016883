import React, { memo } from 'react'
import { useForm, NestDataObject, FieldError } from 'react-hook-form'

import { TextInput } from '../../../components/form/TextInput'
import {
  NativeSelectOptions,
  NativeSelect,
} from '../../../components/form/NativeSelect'
import { Contact } from '../../../db/interfaces/Contact'
import { isValidPhoneNumber } from '../../../util/validation/phone'
import { isValidEmail } from '../../../util/validation/email'

export interface ContactFormProps {
  onSubmit: (values?: any) => void
  onCancel: () => void
  contact?: Contact
}

const contactTypes: NativeSelectOptions[] = [
  {
    value: '',
    label: 'Select Type',
  },
  {
    value: 'GP Surgery',
    label: 'GP Surgery',
  },
  {
    value: 'Pharmacy',
    label: 'Pharmacy',
  },
  {
    value: 'Hospital',
    label: 'Hospital',
  },
  {
    value: 'Specialist',
    label: 'Specialist',
  },
  {
    value: 'Other',
    label: 'Other',
  },
]

const errorsMap: Record<string, string> = {
  phone: 'Enter valid phone number',
  email: 'Enter valid email address',
}

function getError<T>(errors: NestDataObject<T>, field: keyof T) {
  const err = errors[field] as FieldError
  if (!err) {
    return null
  }
  if (err.message) {
    return err.message
  }

  if (errorsMap[err.type]) {
    return errorsMap[err.type]
  }
  return null
}

export const ContactForm = memo<ContactFormProps>(function ContactForm(props) {
  const { onSubmit, onCancel, contact } = props
  const { register, errors, handleSubmit } = useForm<Contact>({
    defaultValues: contact,
  })

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="form">
        <TextInput inputRef={register} name="name" label="Contact name" />
        <TextInput inputRef={register} name="address" label="Address" />
        <TextInput
          inputRef={register({
            validate: {
              phone: isValidPhoneNumber,
            },
          })}
          error={getError(errors, 'phone')}
          name="phone"
          label="Telephone number"
        />
        <TextInput
          inputRef={register({
            validate: {
              email: isValidEmail,
            },
          })}
          error={getError(errors, 'email')}
          name="email"
          label="Email address"
        />
        <NativeSelect
          selectRef={register}
          name="type"
          label="Contact type"
          options={contactTypes}
          defaultValue={''}
        />
        <TextInput inputRef={register} name="description" label="Description" />

        {contact ? <input ref={register} name="id" type="hidden" /> : null}

        <div className="button-container">
          <div className="form-control">
            <button type="submit" className="btn btn-primary-2 w-full">
              {contact ? 'Edit' : 'Add'}
            </button>
          </div>
          <div className="form-control">
            <button
              onClick={e => {
                e.preventDefault()
                onCancel()
              }}
              className="btn w-full"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </form>
  )
})

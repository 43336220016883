import React, { memo } from 'react'
import Modal from 'react-modal'
import { HeartRateForm } from './HeartRateForm'

export interface NewHeartRateModalProps {
  isOpen: boolean
  onSubmit: (values: any) => void
  onCancel: () => void
}
export const NewHeartRateModal = memo<NewHeartRateModalProps>(
  function NewHeartRateModal(props) {
    const { isOpen, onSubmit, onCancel } = props
    return (
      <Modal isOpen={isOpen}>
        <div className="modal-header">
          <div style={{ padding: 0 }} className="modal-title">
            New Heart Rate
          </div>
          <div className="modal-subtitle">
            Enter a new heart rate in this form
          </div>
        </div>

        <HeartRateForm onSubmit={onSubmit} onCancel={onCancel} />
      </Modal>
    )
  },
)

import React, { memo, useState } from 'react'

import { useAction } from '../../../../util/useAction'
import { ActionTypes } from '../../../../state/actions'
import { useDbSelector } from '../../../../util/useDbSelector'
import { BloodGlucoseCollection } from '../../../../db/bloodGlucose'
import { BloodGlucose } from '../../../../db/interfaces/BloodGlucose'
import { BloodGlucoseList } from './BloodGlucoseList'
import { ListToggle } from '../../ListToggle'
import { NewBloodGlucoseModal } from './NewBloodGlucoseModal'
import { EditBloodGlucoseModal } from './EditBloodGlucoseModal'

export const BloodGlucoseContent = memo(function BloodGlucoseContent() {
  const [showModalNew, setShowModalNew] = useState(false)
  const hideModalNew = () => setShowModalNew(false)

  const [showModalEdit, setShowModalEdit] = useState(false)
  const hideModalEdit = () => setShowModalEdit(false)

  const [recordId, setRecordId] = useState(-1)

  const saveRecord = useAction(ActionTypes.BLOOD_GLUCOSE_SAVE)

  const records = useDbSelector<BloodGlucose>(BloodGlucoseCollection)

  const record = records.find(r => r.id === recordId)

  const onEdit = (id: number) => {
    setRecordId(id)
    setShowModalEdit(true)
  }

  const onSubmitNew = (record: Partial<BloodGlucose>) => {
    saveRecord(record)
    hideModalNew()
  }

  const onSubmitEdit = (record: Partial<BloodGlucose>) => {
    saveRecord(record)
    hideModalEdit()
  }

  const deleteRecord = (id: number | string) =>
    BloodGlucoseCollection.delete(id)

  return (
    <div>
      <div className="health-stats-top-bar">
        <div className="stat-description">Blood Glucose</div>
        <button onClick={() => setShowModalNew(true)} className="btn">
          Add
        </button>
      </div>
      <NewBloodGlucoseModal
        isOpen={showModalNew}
        onSubmit={onSubmitNew}
        onCancel={hideModalNew}
      />
      <EditBloodGlucoseModal
        isOpen={showModalEdit}
        glucose={record}
        onCancel={hideModalEdit}
        onSubmit={onSubmitEdit}
      />
      <ListToggle recordsCount={records.length}>
        <BloodGlucoseList
          glucoseRecords={records}
          onDelete={deleteRecord}
          onEdit={onEdit}
        />
      </ListToggle>
    </div>
  )
})

import React, { memo } from 'react'
import Modal from 'react-modal'
import { ConsultationsForm } from './ConsultationsForm'

export interface NewConsultationModalProps {
  isOpen: boolean
  onSubmit: (values: any) => void
  onCancel: () => void
}
export const NewConsultationModal = memo<NewConsultationModalProps>(
  function NewConsultationModal(props) {
    const { isOpen, onSubmit, onCancel } = props
    return (
      <Modal isOpen={isOpen}>
        <div className="modal-header">
          <div style={{ padding: 0 }} className="modal-title">
            New Consultation
          </div>
          <div className="modal-subtitle">
            Enter a new consultation in this form
          </div>
        </div>

        <ConsultationsForm onSubmit={onSubmit} onCancel={onCancel} />
      </Modal>
    )
  },
)

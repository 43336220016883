import React, { memo } from 'react'
import { useForm } from 'react-hook-form'

import { Medicine } from '../../../db/interfaces/Medicine'
import { TextInput } from '../../../components/form/TextInput'
import NativeSelect, {
  NativeSelectOptions,
} from '../../../components/form/NativeSelect'

export interface MedicineFormProps {
  medicine?: Medicine
  onSubmit: (values?: any) => void
  onCancel: () => void
}

const yearOptions: NativeSelectOptions[] = [
  {
    label: '2020',
    value: '2020',
  },
  {
    label: '2019',
    value: '2019',
  },
  {
    label: '2018',
    value: '2018',
  },
  {
    label: '2017',
    value: '2017',
  },
  {
    label: '2016',
    value: '2016',
  },
  {
    label: '2015 and older',
    value: '2015',
  },
]

export const MedicineForm = memo<MedicineFormProps>(function MedicineForm(
  props,
) {
  const { onSubmit, onCancel, medicine } = props
  const { register, handleSubmit } = useForm<Medicine>({
    defaultValues: medicine,
  })
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="form">
        <TextInput
          inputRef={register}
          name="medicine"
          label="Enter new medicine"
        />
        <NativeSelect
          selectRef={register}
          name="year"
          label="Select year"
          options={yearOptions}
          defaultValue={''}
        />
        <TextInput inputRef={register} name="description" label="Description" />

        {medicine ? <input ref={register} name="id" type="hidden" /> : null}

        <div className="button-container">
          <div className="form-control">
            <button type="submit" className="btn btn-primary-2 w-full">
              {medicine ? 'Edit' : 'Add'}
            </button>
          </div>
          <div className="form-control">
            <button
              onClick={e => {
                e.preventDefault()
                onCancel()
              }}
              className="btn w-full"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </form>
  )
})

import React, { memo } from 'react'
import cx from 'clsx'
import { IconProps } from './IconProps'

export const EConsultationIcon = memo<IconProps>(props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 35 48"
    className={cx('e-consultation-icon', props.className)}
  >
    <path
      className="fg"
      d="M19.092 47.452H3.53A3.534 3.534 0 0 1 0 43.922V13.89a3.533 3.533 0 0 1 3.53-3.529h13.374a.526.526 0 0 1 0 1.053H3.53a2.48 2.48 0 0 0-2.476 2.476v30.034a2.48 2.48 0 0 0 2.476 2.476h15.563a2.48 2.48 0 0 0 2.477-2.476v-25.12a.526.526 0 0 1 1.053 0v25.12a3.534 3.534 0 0 1-3.53 3.53"
    />
    <path
      className="fg"
      d="M18.717 16.123H.757a.527.527 0 0 1 0-1.053h17.96a.527.527 0 0 1 0 1.053"
    />
    <path
      className="fg"
      d="M21.875 42.983H.756a.527.527 0 0 1 0-1.053h21.119a.527.527 0 0 1 0 1.053"
    />
    <path
      className="fg"
      d="M13.717 44.813h-4.82a.526.526 0 0 1 0-1.053h4.82a.527.527 0 0 1 0 1.053"
    />
    <path
      className="fg"
      d="M5.336 36.392a.527.527 0 0 1-.526-.526v-3.334c0-1.867 1.488-3.386 3.318-3.386h.319a3.433 3.433 0 0 1-.535-1.844v-2.145c0-1.868 1.488-3.387 3.318-3.387h.155c1.83 0 3.318 1.519 3.318 3.387v2.145c0 .664-.19 1.302-.535 1.844h.32c1.829 0 3.317 1.519 3.317 3.386v3.334c0 .29-.236.526-.526.526zm.527-1.053h10.89v-2.807c0-1.287-1.016-2.334-2.266-2.334h-1.539a.526.526 0 0 1-.3-.959 2.353 2.353 0 0 0 1.003-1.937v-2.145c0-1.288-1.017-2.334-2.266-2.334h-.155c-1.25 0-2.266 1.046-2.266 2.334v2.145c0 .777.375 1.502 1.003 1.937a.527.527 0 0 1-.3.96h-1.54c-1.248 0-2.264 1.046-2.264 2.333z"
    />
    <path
      className="fg"
      d="M25.567 18.931c-.086 0-.172-.002-.257-.004-.024.003-.041.002-.074.004h-8.94a.526.526 0 0 1-.035-1.051c.464-.033 1.874-.285 2.243-.983.18-.34.1-.804-.237-1.38-.07-.121-.155-.25-.25-.385a4.014 4.014 0 0 1-.356-.573 9.3 9.3 0 0 1-1.43-4.963c0-5.148 4.188-9.336 9.336-9.336 5.147 0 9.335 4.188 9.335 9.336 0 5.147-4.188 9.335-9.335 9.335m-.345-1.06h.018c.109.004.218.008.327.008 4.566 0 8.282-3.716 8.282-8.283 0-4.568-3.716-8.283-8.282-8.283-4.567 0-8.283 3.715-8.283 8.283 0 1.572.444 3.103 1.285 4.427a.53.53 0 0 1 .034.064c.032.07.105.203.27.432.115.161.217.319.304.467.533.912.62 1.722.257 2.405a2.03 2.03 0 0 1-.364.488h6.06a.529.529 0 0 1 .092-.008"
    />
    <path
      className="fg"
      d="M24.094 15.033a.527.527 0 0 1-.526-.526v-2.632h-2.632a.527.527 0 0 1-.526-.526V8.454c0-.29.236-.526.526-.526h2.632V5.296c0-.29.236-.526.526-.526h2.895c.29 0 .526.236.526.526v2.632h2.632c.29 0 .526.236.526.526v2.895c0 .29-.236.526-.526.526h-2.632v2.632c0 .29-.236.526-.526.526zm.527-1.052h1.841v-2.632c0-.29.236-.526.527-.526h2.63V8.98h-2.63a.526.526 0 0 1-.527-.527V5.823h-1.841v2.631c0 .29-.236.527-.526.527h-2.631v1.842h2.63c.291 0 .527.235.527.526z"
    />
  </svg>
))

import React, { memo } from 'react'
import cx from 'clsx'

export interface TextAreaProps
  extends React.DetailedHTMLProps<
    React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  > {
  error?: string | null
  label: string
  inputRef?: any
  required?: boolean
}
export const TextArea = memo<TextAreaProps>(function TextArea(props) {
  const { label, error, required, inputRef, ...inputProps } = props
  return (
    <div className="form-control">
      <label>
        {label} {required ? <sup>*</sup> : ''}
      </label>
      <textarea
        ref={inputRef}
        {...inputProps}
        className={cx(
          'text-input',
          inputProps.className,
          error ? 'error' : null,
        )}
      ></textarea>
      {error ? <div className="error-message">{error}</div> : null}
    </div>
  )
})

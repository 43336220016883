import React, { memo } from 'react'
import { BloodPressure } from '../../../../db/interfaces/BloodPressure'
import { ConfirmButton } from '../../../../components/ConfirmButton'

export interface BloodPressureListProps {
  pressureRecords: BloodPressure[]
  onDelete: (id: string | number) => void | Promise<void>
  onEdit: (id: number) => void
}

export const BloodPressureList = memo<BloodPressureListProps>(
  function BloodPressureList(props) {
    const { pressureRecords, onEdit, onDelete } = props
    return (
      <div className="blood-glucose-list list">
        {pressureRecords.map(pressure => (
          <div className="box">
            <div className="box-row">
              <div className="title box-cell">Date: </div>
              <div className="value box-cell">{pressure.date}</div>
            </div>

            <div className="box-row">
              <div className="title box-cell"> Time: </div>
              <div className="value box-cell">{pressure.time}</div>
            </div>

            <div className="box-row">
              <div className="box-cell title"> Systolic: </div>
              <div className="box-cell value">{pressure.systolic}</div>
            </div>

            <div className="box-row">
              <div className="box-cell title"> Diastolic: </div>
              <div className="box-cell value">{pressure.diastolic}</div>
            </div>

            <div className="box-row actions">
              <div className="box-cell">
                <button
                  onClick={() => onEdit(pressure.id)}
                  className="btn flat btn-primary-2-inversed w-full action-edit"
                >
                  Edit
                </button>
              </div>
              <div className="box-cell">
                <ConfirmButton
                  onConfirm={() => onDelete(pressure.id)}
                  className="btn flat btn-primary-2-inversed w-full action-delete"
                >
                  Delete
                </ConfirmButton>
              </div>
            </div>
          </div>
        ))}
      </div>
    )
  },
)

import React, { memo, useRef, useEffect, useState, ReactNode } from 'react'
import cx from 'clsx'

import { AccordionHeader, AccordionHeaderProps } from './AccordionHeader'

export interface AccordionPaneProps {
  expanded?: boolean
  headerProps: AccordionHeaderProps
  name: string
  title: string
  children?: ReactNode
  className?: string
}
export const AccordionPane = memo<AccordionPaneProps>(function AccordionPane(
  props,
) {
  const content = useRef<HTMLDivElement>(null)
  const [scrollHeight, setScrollHeight] = useState(0)
  const elementScrollHeight = content?.current?.scrollHeight
  useEffect(() => {
    if (content?.current?.scrollHeight) {
      setScrollHeight(content?.current?.scrollHeight)
    }
  }, [content, elementScrollHeight])

  const maxHeight = !props.expanded ? '0px' : `${scrollHeight}px`
  return (
    <div
      className={cx(
        'accordion-pane',
        props.className,
        props.expanded ? 'expanded' : 'collapsed',
      )}
    >
      <AccordionHeader {...props.headerProps}>{props.title}</AccordionHeader>

      <div
        ref={content}
        // style={{ maxHeight }}
        className={cx('accordion-content-container')}
      >
        <div className="accordion-content">{props.children}</div>
      </div>
    </div>
  )
})

import React, { memo } from 'react'
import Modal from 'react-modal'
import { BodyMassIndexForm } from './BodyMassIndexForm'
import { BodyMassIndex } from '../../../../db/interfaces/BodyMassIndex'

export interface EditBodyMassIndexModalProps {
  isOpen: boolean
  onSubmit: (values: any) => void
  onCancel: () => void
  bmi?: BodyMassIndex
}

export const EditBodyMassIndexModal = memo<EditBodyMassIndexModalProps>(
  function EditBodyMassIndexModal(props) {
    const { isOpen, onSubmit, onCancel, bmi } = props
    return (
      <Modal isOpen={isOpen}>
        <div className="modal-header">
          <div style={{ padding: 0 }} className="modal-title">
            Edit BMI
          </div>
          <div className="modal-subtitle">Edit bmi in this form</div>
        </div>

        <BodyMassIndexForm
          massIndex={bmi}
          onSubmit={onSubmit}
          onCancel={onCancel}
        />
      </Modal>
    )
  },
)

import React, { memo, useState } from 'react'

import { useAction } from '../../util/useAction'
import { ActionTypes } from '../../state/actions'
import { useDbSelector } from '../../util/useDbSelector'
import { ContactsCollection } from '../../db/contacts'
import { Contact } from '../../db/interfaces/Contact'
import { ContactList } from './Contacts/ContactList'
import { EditContactModal } from './Contacts/EditContactModal'
import { NewContactModal } from './Contacts/NewContactModal'

export const Contacts = memo(function Contacts() {
  const [showModalNew, setShowModalNew] = useState(false)
  const hideModalNew = () => setShowModalNew(false)

  const [showModalEdit, setShowModalEdit] = useState(false)
  const hideModalEdit = () => setShowModalEdit(false)

  const [recordId, setRecordId] = useState(-1)

  const saveRecord = useAction(ActionTypes.CONTACT_SAVE)

  const records = useDbSelector<Contact>(ContactsCollection)

  const record = records.find(r => r.id === recordId)

  const onEdit = (id: number) => {
    setRecordId(id)
    setShowModalEdit(true)
  }

  const onSubmitNew = (record: Partial<Contact>) => {
    saveRecord(record)
    hideModalNew()
  }

  const onSubmitEdit = (record: Partial<Contact>) => {
    saveRecord(record)
    hideModalEdit()
  }

  const deleteRecord = (id: number | string) => ContactsCollection.delete(id)

  return (
    <div className="contacts-pane content">
      <div className="health-stats-top-bar">
        <div className="stat-description">Contacts</div>
        <button onClick={() => setShowModalNew(true)} className="btn">
          Add
        </button>
      </div>

      <NewContactModal
        isOpen={showModalNew}
        onSubmit={onSubmitNew}
        onCancel={hideModalNew}
      />

      <EditContactModal
        isOpen={showModalEdit}
        onSubmit={onSubmitEdit}
        onCancel={hideModalEdit}
        contact={record}
      />

      <ContactList contacts={records} onDelete={deleteRecord} onEdit={onEdit} />
    </div>
  )
})

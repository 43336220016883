import React, { memo, useEffect, useRef } from 'react'

import compassIconSrc from '../../assets/images/compass-icon.svg'
import { PageHeader } from '../../components/app/PageHeader'
import NativeSelect, {
  NativeSelectOptions,
} from '../../components/form/NativeSelect'

const services: NativeSelectOptions[] = [
  {
    value: 'doctor',
    label: 'GP Surgery',
  },
  {
    value: 'hospital',
    label: 'Hospital',
  },
  {
    value: 'dentist',
    label: 'Dentist',
  },
  {
    value: 'pharmacy',
    label: 'Pharmacy',
  },
]

const getPos = (): Promise<{ lat: number; lng: number }> => {
  return new Promise((ok, fail) => {
    navigator.geolocation.getCurrentPosition(pos => {
      ok({
        lat: pos.coords.latitude,
        lng: pos.coords.longitude,
      })
    }, fail)
  })
}
export const ServicesNearPage = memo(function ServicesNearPage() {
  // @ts-ignore
  let map
  let pos: { lat: number; lng: number }
  let placesService: google.maps.places.PlacesService
  let infoWindow: google.maps.InfoWindow
  let markers: google.maps.Marker[] = []

  const clearMarkers = () => markers.forEach(m => m.setMap(null))

  const showMarkerDetails = (
    marker: google.maps.Marker,
    place: google.maps.places.PlaceResult,
  ) => {
    const request = { placeId: place.place_id as string }
    let hasDetails = false

    google.maps.event.addListener(marker, 'click', () => {
      placesService.getDetails(request, (place, status) => {
        hasDetails = true
        const markerContent = `
            <div> ${place.name} </div>
            <div> ${place.adr_address} </div>
            <div> ${
              place.website
                ? `<a href=${place.website} target="_blank"> ${place.website}</a>`
                : ''
            } </div>
            <div>
                ${
                  place.formatted_phone_number
                    ? `<a href="tel:${place.formatted_phone_number}"> ${place.formatted_phone_number} </a>`
                    : ''
                } </div>
        `
        infoWindow.setContent(markerContent)
        // @ts-ignore
        infoWindow.open(map, marker)
      })
    })
  }

  const serviceSelect = useRef<HTMLSelectElement>()

  const searchService = () => {
    // @ts-ignore
    placesService = new google.maps.places.PlacesService(map)
    const coords = new google.maps.LatLng(pos.lat, pos.lng)
    placesService.nearbySearch(
      {
        location: coords,
        radius: 3500,
        type: serviceSelect.current?.value,
      },
      places => {
        clearMarkers()
        markers = places.map(place => {
          const marker = new google.maps.Marker({
            //@ts-ignore
            map,
            position: place.geometry?.location,
          })
          showMarkerDetails(marker, place)

          return marker
        })
      },
    )
  }

  useEffect(() => {
    const googleMapsScript = document.createElement('script')
    googleMapsScript.src = `https://maps.googleapis.com/maps/api/js?v=3&key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`
    googleMapsScript.async = true
    googleMapsScript.defer = true
    googleMapsScript.onload = initialize
    document.head.appendChild(googleMapsScript)
  })

  const initialize = async () => {
    pos = await getPos()
    infoWindow = new google.maps.InfoWindow()
    // @ts-ignore
    map = new window.google.maps.Map(document.getElementById('map'), {
      zoom: 12,
      center: { lat: pos?.lat || 51.5007292, lng: pos.lng || -0.1246254 },
      minZoom: 4,
      maxZoom: 20,
      fullscreenControl: false,
      controlSize: 30,
      zoomControl: false,
      mapTypeControl: false,
      streetViewControlOptions: {
        position: window.google.maps.ControlPosition.RIGHT_BOTTOM,
      },
    })
  }

  return (
    <div className="page services-near-page">
      <PageHeader
        title="Services near you"
        icon={<img src={compassIconSrc} className="heartbeat-icon" />}
      />
      <div className="content section-header">
        Find your local healthcare services in your area
      </div>
      <div className="content content-primary-2">
        To see health-related services in your area, please allow location
        services to detect your location. If you choose not to share your
        current location, you can enter a postcode to display services
      </div>
      <div className="content">
        <div style={{ height: '440px' }} id="map"></div>
      </div>
      <div className="content">
        <NativeSelect
          selectRef={serviceSelect}
          name="type"
          label="Select a service"
          options={services}
          defaultValue={services[0].value}
        />
        <button onClick={searchService} className="btn btn-primary-2 w-full">
          Search
        </button>
      </div>
    </div>
  )
})

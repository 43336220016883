import React, { memo, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { BackIcon } from '../icons/BackIcon'

import logoSrc from '../../assets/images/logo-icon.png'
import { HeaderBg } from '../svg/HeaderBg'
import { HeaderBgMobile } from '../svg/HeaderBgMobile'

export interface PageHeaderProps {
  icon: any
  title: string
}

export const PageHeader = memo<PageHeaderProps>(function PageHeader(props) {
  const { title, icon } = props
  const history = useHistory()

  useEffect(() => {
    window.scrollTo(0, 0)
    //@ts-ignore
    window.gtag('config', 'UA-78156948-64', {
      page_path: history.location.pathname,
    })
  }, [])
  return (
    <div className="page-header-container">
      <div className="page-header-top-bar">
        <div className="back-btn" onClick={() => history.goBack()}>
          <BackIcon />
        </div>

        <div className="logo-container">
          <img className="logo" src={logoSrc} alt="logo" />
        </div>
      </div>
      <HeaderBg className="header-bg header-bg-desktop hide-mobile" />
      <HeaderBgMobile
        dropShadow
        className="header-bg header-bg-mobile hide-tablet hide-laptop"
      />
      <div className="header-text">{title}</div>
      <div className="page-icon-container">{icon}</div>
    </div>
  )
})

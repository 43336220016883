import axios from 'axios'

const client = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
})

export interface ResponseMessage {
  success: boolean
  message: string
}

export const requestAppointment = async (appointmentData: any) => {
  const data = new FormData()

  Object.keys(appointmentData).forEach(key => {
    if (appointmentData[key].length && appointmentData[key].item) {
      for (const val of appointmentData[key]) {
        data.append(key, val)
      }
      return
    }

    data.append(key, appointmentData[key])
  })

  const response = await client.post<ResponseMessage>(
    '/appointment-request',
    data,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  )

  return response.data
}

export const messageDr = async (message: any) => {
  const data = new FormData()

  Object.keys(message).forEach(key => {
    if (message[key].length && message[key].item) {
      for (const val of message[key]) {
        data.append(key, val)
      }
      return
    }

    data.append(key, message[key])
  })
  const response = await client.post<ResponseMessage>('/message-doctor', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })

  return response.data
}

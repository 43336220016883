import React, { memo } from 'react'
import { PageHeader } from '../../components/app/PageHeader'
import { CheckmarkIcon } from '../../components/icons/CheckmarkIcon'

export const RequestConfirmedPage = memo(function RequestConfirmedPage() {
  return (
    <div className="page request-confirmed-page">
      <PageHeader title="Request Confirmed" icon={<CheckmarkIcon />} />

      <div className="content section-header">Thank you for contacting us,</div>

      <div className="content paragraph">
        We will send you an email with the next available appointment, within 4
        hours of your request.
      </div>
      <div className="content table-container">
        <table className="appointment-table">
          <tr>
            <td className="text-left">Monday - Sunday</td>
            <td className="text-right">08:00-22:00</td>
          </tr>
        </table>
        <div className="content">
          <div className="footnote">Excluding UK bank holidays</div>
        </div>
      </div>
      <div className="content paragraph mt-1">
        If you would prefer a quicker response,{' '}
        <a href="tel:01423 798198" className="call-link">
          call us{' '}
        </a>{' '}
        to arrange an appointment, but please let us know you’ve sent us a
        message.
      </div>
      <div className="content paragraph mt-2">
        By using this service you are agreeing to our{' '}
        <a
          href="https://www.healthhero.com/terms-and-conditions/"
          target="_blank"
          rel="noopener noreferrer"
        >
          {' '}
          terms & conditions
        </a>
      </div>
    </div>
  )
})
